import React, { type FC } from 'react';
import { t } from 'i18next';

import historyTransactionIcon from '../../../../assets/images/history_transaction_icon.svg';

import style from './AccountsTableSubHeader.module.css';

export const AccountsTableSubHeader: FC = () => {
  return (
    <div className={ style.subHeaderWrapper }>
      <svg className={ style.svg }>
        <use xlinkHref={`${historyTransactionIcon}#historyTransaction`}/>
      </svg>
      <p className={ style.subHeaderTitle }>{t('labels.history_transaction')}</p>
    </div>
  )
}
