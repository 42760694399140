import React, { type FC, useState, useEffect, useContext, type Dispatch, type SetStateAction, useMemo } from 'react';
import { socket } from '../../../../web/socket';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getOrders, setUpdatedOrder, type IOrder } from '../../../../redux/reducers/orders';
import { Accordion, AccordionSummary, AccordionDetails, Tab, Tabs, Box } from '@mui/material';
import { type IAssetSocketData, type PayloadInterface, type IMarketToAssetSocketData } from '../../../../interfaces';
import { EditModalContent, StopModalContent, StopModalFooter } from '../../modals';
import { DialogContext } from '../../context/DialogContextProvider';
import { getHistoryOrderSchema } from './components/schemas/historyOrderSchema';
import { activeOrderSchema } from './components/schemas/activeOrderSchema';
import { DataTable } from './components/DataTable';
import { editOrder, stopOrder, addNewOrder } from '../../../../redux/reducers/orders';
import { getAccounts, getInvestedBalance, getTotalBalance, setUniqueId, setOrderStatus, getHiddenPlatformColums } from '../../../../redux/reducers/cfd';
import { getAccounts as getAllAccounts } from '../../../../redux/reducers/accounts';
import { handleError } from '../../../../helpers';
import { useSnackbar, type HandleOpenInterface } from '../../../../components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { selectActiveAccounts } from '../../../../redux/selectors/accounts';
import { TradeElementHeight } from '../../helper';
import { t } from 'i18next';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import styles from './Table.module.css';
import { setModalLoading } from '../../../../redux/reducers/modalSlice';

interface ITableProps {
  setMaxTradingHeight: Dispatch<SetStateAction<number>>
  uniqueId: number
  isTableExpanded: boolean
  setIsTableExpanded: (el: boolean) => void
  calcHeight: number
}

const CustomAccordion = styled(Accordion)(() => ({
  backgroundColor: 'var(--CFD-theme-Surface-1)',
  border: '1px solid var(--CFD-theme-State-layers-outline-opacity_16)',
  borderRadius: '6px',
  height: '65px',
  marginTop: '0',
  width: '100%',
  minWidth: '320px',
  '&.Mui-expanded': {
    margin: 0,
    borderBottom: 'unset',
    borderRadius: '6px 6px 0 0'
  },
  ' .MuiButtonBase-root.MuiAccordionSummary-root': {
    minHeight: '65px',
    padding: '0',
    paddingRight: '8px',
    height: '100%',
    color: 'var(--CFD-theme-System-OnSurface)',
    fontFamily: 'Arial',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '32px',
    ' .MuiAccordionSummary-content': {
      margin: 0,
      height: '100%'
    }
  },
  ' .MuiCollapse-root': {
    margin: '-1px'
  }
}));

const CustomTabs = styled(Tabs)(() => ({
  height: '100%',
  ' .MuiTabs-flexContainer': {
    height: '100%'
  },
  ' .MuiTabs-indicator': {
    backgroundColor: 'var(--CFD-theme-System-Tertiary)'
  }
}))

const CustomTab = styled(Tab)(() => ({
  color: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
  fontSize: '14px !important',
  fontStyle: 'normal !important',
  fontWeight: '400 !important',
  lineHeight: '20px !important',
  letterSpacing: '0.1px !important',
  padding: '14px 16px !important',
  '&.Mui-selected': {
    color: 'var(--CFD-theme-System-OnSurface) !important'
  }
}))

const CustomAccordionDetails = styled(AccordionDetails)(() => ({
  backgroundColor: 'var(--CFD-theme-Surface-1)',
  border: '1px solid var(--CFD-theme-State-layers-outline-opacity_16)',
  padding: '0',
  maxHeight: '160px',
  minHeight: '160px',
  overflowY: 'auto',
  borderRadius: '0 0 6px 6px'
}));

export const Table: FC<ITableProps> = ({ setMaxTradingHeight, uniqueId, isTableExpanded, setIsTableExpanded, calcHeight }) => {
  const dispatch = useAppDispatch();
  const [screenWidth] = useWindowSize();
  const { showDialog, hideDialog } = useContext(DialogContext);
  const { handleOpen: handleOpenSnackbar } = useSnackbar()
  const [tableSelected, setTableSelected] = useState<number>(0);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [isEditModalBtnLoading, setIsEditModalBtnLoading] = useState<boolean>(false);
  const [marketToAssets, setMarketToAssets] = useState<IMarketToAssetSocketData[]>([]);
  const orders = useAppSelector((state) => state.orders);
  const { rates: currencyRates = {} } = useAppSelector(state => state.currencyRates);
  const activeUserAccounts = useAppSelector(selectActiveAccounts);

  const { _id: userId, brandId } = useAppSelector(state => state.user);
  const { activeAccount, orderStatus, hiddenPlatformColumns } = useAppSelector(state => state.cfd);

  const activeAccountSymbol = useMemo(() => {
    return activeUserAccounts?.cfdAccountCurrency.symbol ?? activeUserAccounts?.symbol ?? 'USD';
  }, [activeAccount]);

  useEffect(() => {
    if (userId !== undefined && activeAccount._id.length === 0) {
      dispatch(getAccounts(userId));
    }
  }, [activeAccount, userId]);

  useEffect(() => {
    if (brandId !== undefined) {
      dispatch(getHiddenPlatformColums(brandId));
    }
  }, [brandId]);

  useEffect(() => {
    socket.on(`recieveCFDData&${uniqueId}`, async ({ success, market, prices }: { success: boolean, market: string, prices: IAssetSocketData[] }) => {
      if (success) {
        setMarketToAssets((prev) => {
          const nextTickers = [...(prev ?? [])];

          const index = nextTickers.findIndex((t) => t.market === market);

          if (index === -1) {
            nextTickers.push({ market, prices });
          }

          const updatedIndex = nextTickers.findIndex((t) => t.market === market);

          for (const price of prices) {
            if (price === undefined || (Object.keys(price).length === 0)) continue;

            const priceIndex = nextTickers[updatedIndex]?.prices?.findIndex((np) => np.symbol === price.symbol);

            if (priceIndex !== -1) {
              nextTickers[updatedIndex].prices.splice(priceIndex, 1);
            }

            nextTickers[updatedIndex].prices.push(price);
          }

          return nextTickers;
        });
      }
    });

    socket.on(`onCloseCFDOrder&${userId}`, async () => {
      if (userId !== undefined && activeAccount._id.length > 0 && activeUserAccounts !== undefined) {
        Promise.allSettled([
          dispatch(getOrders({ accountId: activeUserAccounts._id, userId, status: orderStatus })),
          dispatch(getAllAccounts(userId)),
          dispatch(getAccounts(userId)),
          dispatch(getInvestedBalance({ userId, accountId: activeUserAccounts._id })),
          dispatch(getTotalBalance(userId)),
          dispatch(setUniqueId())
        ])
      }
    });

    socket.on(`onOpenCFDOrder&${userId}`, async (order) => {
      const openParams: HandleOpenInterface = {
        message: 'Order was successfully created',
        actionText: '',
        severity: 'success',
        position: { vertical: 'top', horizontal: 'right' },
        autoClose: false
      }

      handleOpenSnackbar(openParams);

      dispatch(addNewOrder(order as IOrder));
      dispatch(setUniqueId());
    });

    socket.on(`onUpdateCFDOrder&${userId}`, (order) => {
      dispatch(setUpdatedOrder(order as IOrder));
      dispatch(setUniqueId());
    })

    return () => {
      socket.off(`receiveOrdersMarket&${uniqueId}`);
      socket.off(`onCloseCFDOrder&${userId}`);
      socket.off(`onOpenCFDOrder&${userId}`);
      setMarketToAssets([]);
    };
  }, [userId, uniqueId, activeAccount._id, activeUserAccounts]);

  const handleEditTPSL = async (takeProfit: number, stopLoss: number, id: string): Promise<void> => {
    setIsEditModalBtnLoading(true);
    const orderData = {
      takeProfitPrice: takeProfit,
      stopLossPrice: stopLoss,
      isTPSLSet: !!(takeProfit > 0 || stopLoss > 0)
    };

    const response = await dispatch(editOrder({ id, orderData }));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      handleError(payload, handleOpenSnackbar);
      setIsEditModalBtnLoading(false);

      return;
    }
    const openParams: HandleOpenInterface = {
      message: 'Order was successfully edit',
      severity: 'success',
      position: { vertical: 'top', horizontal: 'right' },
      actionText: '',
      autoClose: true
    }

    handleOpenSnackbar(openParams);

    setIsEditModalBtnLoading(false);
    hideDialog();
  }

  const handleStopOrder = async (row: IOrder): Promise<void> => {
    dispatch(setModalLoading(true));

    const currentMarket = row.type.name;
    const brokerSymbol = row.brokerSymbol;

    const currentMarketToAssets = marketToAssets.find(({ market }) => market === currentMarket);

    if (currentMarketToAssets !== undefined) {
      const { prices } = currentMarketToAssets;
      const assetData = prices.find(({ symbol }) => symbol === brokerSymbol);

      if (assetData !== undefined && row._id !== undefined) {
        const closePrice = row.tradeType === 1 ? assetData.bid : assetData.ask;

        const response = await dispatch(stopOrder({ id: row._id, closePrice: Number(closePrice) }));
        const payload = response.payload as PayloadInterface;

        if (payload.isError) {
          handleError(payload, handleOpenSnackbar);
          dispatch(setModalLoading(false));
          return;
        }
        const openParams: HandleOpenInterface = {
          message: 'Order was successfully stopped',
          severity: 'success',
          position: { vertical: 'top', horizontal: 'right' },
          actionText: '',
          autoClose: true
        }

        handleOpenSnackbar(openParams);

        if (userId !== undefined && activeUserAccounts !== undefined) {
          Promise.allSettled([
            dispatch(getAccounts(userId)),
            dispatch(getAllAccounts(userId)),
            dispatch(getInvestedBalance({ userId, accountId: activeUserAccounts._id })),
            dispatch(getTotalBalance(userId)),
            dispatch(getOrders({ accountId: activeUserAccounts._id, userId, status: orderStatus }))
          ]);
        }
      }
    }

    dispatch(setUniqueId());
    dispatch(setModalLoading(false));
    hideDialog();
  }

  const callEditDialogModal = (row: IOrder): void => {
    showDialog({
      headerContent: <div className={styles.dialogHeader}>TP/SL</div>,
      bodyContent: <EditModalContent
        entryPrice={row.tradeStartPrice ?? 0}
        liqPrice={row.liquidationPrice ?? 0}
        stopLoss={row.stopLossPrice ?? 0}
        takeProfit={row.takeProfitPrice ?? 0}
        isLoading={isEditModalBtnLoading}
        onClick={(takeProfit, stopLoss) => { handleEditTPSL(takeProfit, stopLoss, row._id ?? ''); }}
       />,
      footerContent: <></>
    })
  }

  const callStopDialogModal = (row: IOrder): void => {
    showDialog({
      headerContent: <></>,
      bodyContent: <StopModalContent position={row.position ?? 0} tradeType={row.tradeType ?? 0} symbol={row.setting?.symbol ?? ''} />,
      footerContent: <StopModalFooter onClick={() => { handleStopOrder(row); }} />
    })
  }

  const tableSchema = useMemo(() => {
    if (orderStatus === 'processing,pending') {
      return activeOrderSchema({
        onEditClick: callEditDialogModal,
        onStopClick: callStopDialogModal,
        marketToAssets,
        currencyRates,
        activeAccountSymbol,
        hiddenPlatformColumns
      });
    }
    if (orderStatus === 'completed,stopped') {
      return getHistoryOrderSchema(activeAccountSymbol, currencyRates, marketToAssets, hiddenPlatformColumns);
    }
  }, [marketToAssets, orderStatus]);

  useEffect(() => {
    const getOrdersByStatus = async (): Promise<void> => {
      if (activeAccount._id.length > 0 && userId !== undefined && activeUserAccounts !== undefined) {
        setIsTableLoading(true);
        await dispatch(getOrders({ accountId: activeUserAccounts._id, userId, status: orderStatus }));
        setIsTableLoading(false);
      }
    };

    getOrdersByStatus();
  }, [activeAccount._id, userId, orderStatus, activeUserAccounts]);

  const handleChangeTable = (value: number): void => {
    setTableSelected(value);
    dispatch(setOrderStatus(value === 0 ? 'processing,pending' : 'completed,stopped'));
  }

  const dynamicAccordionDetailsStyle = useMemo(() => {
    const isSmallHeight = calcHeight < 522;
    const minimumTradeHeight = 250;
    const calcSmallTableHeight = calcHeight - minimumTradeHeight - TradeElementHeight.PaddingTop - TradeElementHeight.GapBetweenTradeAndTable - TradeElementHeight.OrderAccordionHeader;
    const isMobile = screenWidth <= 600;

    const resultCalcSmallTableHeight = calcSmallTableHeight < 75 ? 75 : calcSmallTableHeight;
    const mobileHeight = !isMobile ? `${resultCalcSmallTableHeight}px` : '160px'

    return {
      maxHeight: isSmallHeight ? mobileHeight : '160px',
      minHeight: isSmallHeight ? mobileHeight : '160px'
    };
  }, [calcHeight]);

  const handleChangeAccordion = (value: boolean): void => {
    setIsTableExpanded(value);
    const maxTradeHeight = calcHeight - TradeElementHeight.PaddingTop;
    const minTradeHeight = maxTradeHeight - TradeElementHeight.OrderAccordionBody;

    const height = value ? minTradeHeight : maxTradeHeight;

    setMaxTradingHeight(height);
  }

  return (
        <CustomAccordion expanded={isTableExpanded} onChange={(e, value) => { handleChangeAccordion(value) }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />}
                aria-controls="news-content"
                id="news-header"
            >
                <Box onClick={(e) => { e.stopPropagation(); }} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <CustomTabs value={tableSelected} onChange={(e, value) => { handleChangeTable(value as number) }}>
                        <CustomTab label={t('labels.active_orders')} />
                        {/* <CustomTab label="Pending Orders" /> */}
                        <CustomTab label={t('labels.closed_orders')} />
                    </CustomTabs>
                </Box>
            </AccordionSummary>
            <CustomAccordionDetails sx={dynamicAccordionDetailsStyle}>
              <DataTable orders={orders} columns={tableSchema} isLoading={isTableLoading} tableSelected={tableSelected} />
            </CustomAccordionDetails>
        </CustomAccordion>
  )
}
