import { createSlice } from '@reduxjs/toolkit';
import { depositTypesApi } from '../api/depositTypesApi';

export interface DepositType {
  _id: string
  name: string
  status: boolean
  createdAt: string
  updatedAt: string
}

interface DepositTypesState {
  depositTypes: DepositType[]
  loading: boolean
  error: string | null
}

const initialState: DepositTypesState = {
  depositTypes: [],
  loading: false,
  error: null
};

const depositTypesSlice = createSlice({
  name: 'depositTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        depositTypesApi.endpoints.getDepositTypes.matchPending,
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        depositTypesApi.endpoints.getDepositTypes.matchFulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.depositTypes = payload.success === true ? payload.data : [];
        }
      )
      .addMatcher(
        depositTypesApi.endpoints.getDepositTypes.matchRejected,
        (state, { error }) => {
          state.loading = false;
          state.error = error.message ?? 'Failed to fetch deposit types';
        }
      );
  }
});

export default depositTypesSlice.reducer;
