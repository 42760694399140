import React, { useEffect, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux';
import { type RouteLayoutInterface } from '../interfaces';

export const AuthLayout: FC<RouteLayoutInterface> = ({ isUserAuth, children }): JSX.Element => {
  const navigate = useNavigate();
  const { startAsset } = useAppSelector(state => state.cfd);

  useEffect(() => {
    if (isUserAuth && startAsset.length > 0) navigate(`/trade?asset=${startAsset}`);
  }, [isUserAuth, startAsset]);

  return (
    <>
      {children}
    </>
  );
}
