import React, { type FC, useState, type Dispatch, type SetStateAction, useEffect } from 'react';
import { t } from 'i18next';

import { useAppSelector } from '../../../../hooks/redux';
import { SelectComponent, AssetAmountInputComponent } from '../../../../components'
import { type OptionInterface } from '../../../../interfaces';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { type SelectChangeEvent } from '@mui/material';

import style from './BetweenAccountsForm.module.css';

interface InterfaceBetweenAcountsForm {
  options: OptionInterface[]
  fromAccount: IAccount | null
  toAccount: IAccount | null
  direction: 'from' | 'to'
  changeOption: Dispatch<SetStateAction<IAccount | null>>
  toAccountValue?: number
  error?: boolean
  value: string
  setValue: (value: string) => void
  changeToValue?: (value: string) => void
  setFromAccount: (el: IAccount | null) => void
  setToAccount: (el: IAccount | null) => void
}

const labelTitle = {
  to: `${t('labels.to')} ${t('labels.account')}`,
  from: `${t('labels.from')} ${t('labels.account')}`
}

export const BetweenAcountsForm: FC<InterfaceBetweenAcountsForm> = (
  {
    options,
    fromAccount,
    toAccount,
    direction,
    error = false,
    value,
    setValue,
    changeOption,
    changeToValue = () => {},
    toAccountValue = 0,
    setFromAccount,
    setToAccount
  }) => {
  const [account, setAccount] = useState<IAccount | null>(null);
  const accounts = useAppSelector((state) => state.accounts);

  useEffect(() => {
    setAccount(direction === 'from' ? fromAccount : toAccount);
  }, [fromAccount, toAccount, direction]);

  useEffect(() => {
    if (direction === 'to') {
      setValue(String(toAccountValue))
    }
  }, [toAccountValue, direction]);

  const handleAmountChange = (value: string): void => {
    setValue(value);
    changeToValue(value);
  }

  const selectHandler = (event: SelectChangeEvent<unknown>): void => {
    const { target: { value } } = event;
    if (value === toAccount?._id) setToAccount(fromAccount);
    if (value === fromAccount?._id) setFromAccount(toAccount)
    changeOption(accounts.find(({ _id }) => _id === value) ?? null);
  }

  return (
    <div className={ style.form }>
      <div className={ style.inputWrapper }>
        <label
          htmlFor="account"
          className={ style.label }
        >{ labelTitle[direction] }:</label>
        <SelectComponent
          value={ account?._id ?? '' }
          options={options}
          onChange={selectHandler}
          id="account"
        />
      </div>
      <div className={ style.inputWrapper }>
        <label
          htmlFor={`amount-${account?.cfdAccountCurrency.symbol}`}
          className={ style.label }
        >{t('labels.amount')}:</label>
        <AssetAmountInputComponent
          value={ value }
          setValue={ handleAmountChange }
          currency={account?.cfdAccountCurrency.symbol}
          id={`amount-${account?.cfdAccountCurrency.symbol}`}
          disabled={direction === 'to'}
          placeholder='0.00'
          error={ error }
          maxBtn={ false }
          showCurrency={ false }
        />
      </div>
    </div>
  );
}
