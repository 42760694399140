import React, { type MouseEvent, type FC } from 'react';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';

import { ButtonComponent, DividerComponent } from '../../../../components';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { FormControllerHeaderAccountSelect } from '../../FormControllerHeaderAccountSelect/FormControllerHeaderAccountSelect';

import styles from '../SelectAccountHeader.module.css';
import { setShowFooter } from '../../../../redux/reducers/showFooter';
import { useAppDispatch } from '../../../../hooks/redux';

interface IMobileSelectAccountHeader {
  setOpenSelectAccounts: (el: boolean) => void
  setOpenDeposit: (el: boolean) => void
  setChosenSelectedAccount: (el: IAccount | null) => void
  setOpenWithdrawal: (el: boolean) => void
  selectedAccount: IAccount | null
}

export const MobileSelectAccountHeader: FC<IMobileSelectAccountHeader> = ({
  setOpenSelectAccounts,
  setOpenDeposit,
  setChosenSelectedAccount,
  setOpenWithdrawal,
  selectedAccount
}) => {
  const dispatch = useAppDispatch();
  const stopPropagation = (event: MouseEvent): void => {
    event.stopPropagation();
  };

  const exitHandler = (): void => {
    setOpenSelectAccounts(false);
    dispatch(setShowFooter(true))
  }

  return (
    <div className={ styles.background } onClick={ () => { setOpenSelectAccounts(false); } }>
      <div className={ styles.container }>
        <div
          className={ styles.mobileWrapper }
          onClick={ stopPropagation }
        >
          <div className={ styles.titleWrapper } >
            <p className={ styles.title }>{t('labels.selected_account')}</p>
            <button className={ styles.crossContainer } onClick={ exitHandler }>
              <CloseIcon sx={{ width: '24px', height: '24px', color: 'var(--CFD-theme-System-OnSurface)' }} />
            </button>
            </div>
            <DividerComponent />
            <FormControllerHeaderAccountSelect
              selectedAccount={ selectedAccount }
              setOpenDeposit={ setOpenDeposit }
              setOpenSelectAccounts={ setOpenSelectAccounts }
              setChosenSelectedAccount={ setChosenSelectedAccount }
              setOpenWithdrawal={ setOpenWithdrawal }
            />
            <div className={ styles.footer }>
            <div className={ styles.btnFooterContainer }>
              <ButtonComponent variant='outlined' onClick={ exitHandler }>{t('labels.save')}</ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
