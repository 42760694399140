import React, { type FC, useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { socket } from '../../web/socket';
import { Market, Chart, BuySell, News, Table } from './components';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getAssetInfo, getAssetQuote, setUniqueId as setRerenderId } from '../../redux/reducers/cfd';
import { DialogContextProvider } from './context/DialogContext';
import { type SeverityType, useSnackbar, type VerticalPositionType, type HorizontalPositionType } from '../../components';
import useQuery from '../../hooks/useQuery';
import { useWindowSize } from '../../hooks/useWindowSize';
import { MobileViewTrade } from './MobileViewTrade/MobileViewTrade';
import { TradeElementHeight } from './helper'
import { getAlertMessage, setShowAlertMessage, setShowRunningAdvertisement } from '../../redux/reducers/alertMessage';
import { selectAlertMessage, selectIsAlertMessage, selectIsRunningAdvertisement, selectRunningAdvertisement } from '../../redux/selectors/alertMessage';

import styles from './Trade.module.css'

export const Trade: FC = () => {
  const dispatch = useAppDispatch();
  const [screenWidth, screenHeight] = useWindowSize();
  const { handleOpen } = useSnackbar();
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(true);
  const [maxMarketHeight, setMaxMarketHeight] = useState<number>(0);
  const [maxTradingHeight, setMaxTradingHeight] = useState<number>(0);
  const [calcHeight, setCalcHeight] = useState<number>(0);
  const [isMarketShown, setIsMarketShown] = useState<boolean>(true);
  const [isNewsShown, setIsNewsShown] = useState<boolean>(true);
  const [uniqueId, setUniqueId] = useState<number>(Date.now());
  const [isNewsOpened, setIsNewsOpened] = useState<boolean>(false);
  const { assetInfo, assetQuote, activeAccount } = useAppSelector((state) => state.cfd);
  const { _id: userId, brandId: userBrandId } = useAppSelector((state) => state.user);
  const accounts = useAppSelector((state) => state.accounts);
  const isAlertMessage = useAppSelector(selectIsAlertMessage);
  const isRunningAdvertisement = useAppSelector(selectIsRunningAdvertisement);
  const alertMessageData = useAppSelector(selectAlertMessage);
  const runningAdvertisementData = useAppSelector(selectRunningAdvertisement);

  const queryParams = useQuery();
  const asset = queryParams.get('asset') ?? null;

  const isMobile = screenWidth <= 1280;

  const isOpened = useMemo(() => {
    return !(assetQuote.marketHolidays ?? false);
  }, [assetQuote]);

  useEffect(() => {
    dispatch(getAlertMessage())
  }, [])

  useLayoutEffect(() => {
    let alertMessageResult = false;
    let runningAdvertisementResult = false;

    for (let i = 0; i < alertMessageData.length; i++) {
      if (alertMessageData[i].brandId.some((id) => id === userBrandId) && alertMessageData[i].value.length > 0) {
        alertMessageResult = true;
        break;
      }
    }

    for (let i = 0; i < runningAdvertisementData.length; i++) {
      if (runningAdvertisementData[i].brandId.some((id) => id === userBrandId) && runningAdvertisementData[i]?.value) {
        runningAdvertisementResult = true;
        break;
      }
    }

    dispatch(setShowAlertMessage(alertMessageResult));
    dispatch(setShowRunningAdvertisement(runningAdvertisementResult));
    return () => {
      dispatch(setShowAlertMessage(false));
      dispatch(setShowRunningAdvertisement(false));
    }
  }, [alertMessageData, userBrandId, runningAdvertisementData])

  useLayoutEffect(() => {
    if (screenHeight > 0) {
      const alertMessage = isAlertMessage ? TradeElementHeight.AlertMessage : 0;
      const runningAdvertisement = isRunningAdvertisement ? TradeElementHeight.RunningAdvertisement : 0;
      const allPageContentHeight = screenHeight - TradeElementHeight.Footer - TradeElementHeight.Header - alertMessage - runningAdvertisement;
      const calcMarketHeight = allPageContentHeight - TradeElementHeight.PaddingTop;
      const calcTradingHeight = calcMarketHeight - TradeElementHeight.OrderAccordionBody;

      setCalcHeight(allPageContentHeight);
      setMaxMarketHeight(calcMarketHeight)
      setMaxTradingHeight(calcTradingHeight)
    }
  }, [screenHeight, isAlertMessage, isRunningAdvertisement])

  useEffect(() => {
    const handler = setTimeout(() => {
      if (accounts.length === 0) {
        const openParams = {
          message: <>
            You do not have trading accounts available.
            <br />
            Please contact the support
          </>,
          actionText: '',
          position: {
            vertical: 'bottom' as VerticalPositionType,
            horizontal: 'left' as HorizontalPositionType
          },
          header: 'Error',
          severity: 'warning' as SeverityType,
          autoClose: false
        }

        handleOpen(openParams);
      }
    }, 5000);

    return () => {
      clearTimeout(handler);
    };
  }, [accounts]);

  useEffect(() => {
    if (asset !== null) {
      if (activeAccount._id.length > 0) {
        dispatch(getAssetInfo({ name: asset, accountId: activeAccount._id }));
      }
    }
  }, [asset, activeAccount._id]);

  useEffect(() => {
    if (assetInfo.brokerSymbol.length > 0 && userId !== undefined && userId.length > 0) {
      dispatch(getAssetQuote({ symbol: assetInfo.brokerSymbol.replace('#', '%23'), userId }));
      setUniqueId(Date.now());
    }
  }, [assetInfo, userId]);

  useEffect(() => {
    if (userId !== undefined) {
      socket.on(`tsCFDListener${userId}`, () => {
        setUniqueId(Date.now());
        dispatch(setRerenderId());
      });
    }

    return () => {
      socket.off(`tsCFDListener${userId}`);
    };
  }, [userId]);

  useEffect(() => {
    socket.on('onDatafeedSettingChange', () => {
      setUniqueId(Date.now());
      dispatch(setRerenderId());

      const activeAccount = accounts.find((account) => account.isActive) ?? null;

      if (activeAccount !== null && asset !== null) {
        dispatch(getAssetInfo({ name: asset, accountId: activeAccount._id }));
      }

      if (userId !== undefined) {
        dispatch(getAssetQuote({ symbol: assetInfo.brokerSymbol.replace('#', '%23'), userId }));
      }
    })

    return () => {
      socket.off('onDatafeedSettingChange');
    };
  }, [userId, assetInfo, accounts, asset])

  useEffect(() => {
    if (userId !== undefined) {
      socket.emit('getCFDData', { userId, unixTime: uniqueId });
    }

    return () => {
      socket.off(`recieveCFDData&${uniqueId}`);
      socket.emit('removeCFDDataListener');
    };
  }, [uniqueId, userId]);

  const isTradingFullWidth = useMemo(() => {
    return !isNewsShown && !isMarketShown
  }, [isNewsShown, isMarketShown])

  return (
    <DialogContextProvider>
      {!isMobile
        ? <div className={styles.contentWrapper} style={{ height: `${calcHeight}px` }}>
            {
              !isTradingFullWidth && (
                <div className={styles.marketBoard} style={{ height: '100%' }}>
                  <Market maxHeight={maxMarketHeight} uniqueId={uniqueId} isMarketShown={isMarketShown} isNewsOpened={ isNewsOpened }/>
                  <News
                    setMaxMarketHeight={setMaxMarketHeight}
                    isMarketShown={isMarketShown}
                    isNewsShown={isNewsShown}
                    calcHeight={ calcHeight }
                    isNewsOpened={ isNewsOpened }
                    setIsNewsOpened={ setIsNewsOpened }
                  />
                </div>
              )
            }
            <div
              className={`${styles.tradingBoard}  ${isTradingFullWidth ? styles.tradingBoardFullWidth : ''}`}
              style={{ height: `${maxTradingHeight}px` }}
            >
                <div className={styles.trading}
                >
                  <Chart
                    isOpened={isOpened}
                    uniqueId={uniqueId}
                    isNewsShown={isNewsShown}
                    isMarketShown={isMarketShown}
                    setIsMarketShown={setIsMarketShown}
                    setIsNewsShown={setIsNewsShown}
                    calcHeight={ calcHeight }
                  />
                  <BuySell isOpened={isOpened} uniqueId={uniqueId} />
                </div>
                  <Table
                    uniqueId={ uniqueId }
                    setMaxTradingHeight={ setMaxTradingHeight }
                    isTableExpanded={ isTableExpanded }
                    setIsTableExpanded={ setIsTableExpanded }
                    calcHeight={ calcHeight }
                  />
            </div>
          </div>
        : <MobileViewTrade
            uniqueId={ uniqueId }
            isMarketShown={isMarketShown}
            isNewsShown={ isNewsShown }
            isOpened={ isOpened }
            setIsMarketShown={ setIsMarketShown }
            setIsNewsShown={ setIsNewsShown }
            setMaxTradingHeight={ setMaxTradingHeight }
            calcHeight={ calcHeight }
          />}
    </DialogContextProvider>
  )
}
