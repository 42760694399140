import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './axiosBaseQuery';

export const depositTypesApi = createApi({
  reducerPath: 'depositTypesApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getDepositTypes: builder.query({
      query: () => ({
        method: 'GET',
        path: '/deposit-type/active'
      })
    })
  })
});

export const { useGetDepositTypesQuery } = depositTypesApi;
