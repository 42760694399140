import React, { type FC } from 'react';
import { t } from 'i18next';

import { getCurrencySymbol } from '../../../../../helpers/util';
import { isCardValid } from '../../../helper';
import { type sectionNameType } from '../SuccessWithdrawalSection';

import style from '../SuccessWithdrawalSection.module.css';

interface InterfaceSuccessWithdrawalCell {
  withdrawalTo: string
  amount: string
  currency: string | null
  bankName?: string
  bankCurrency?: string
  sectionName: sectionNameType
  formattedDateTime: string
  iban?: string
  distinctRate?: string
  cryptoCurrency?: string
  totalWithdrawals?: string
  walletAdress?: string
}

export const SuccessWithdrawalCell: FC<InterfaceSuccessWithdrawalCell> = ({
  amount,
  withdrawalTo,
  sectionName,
  currency,
  bankName,
  bankCurrency,
  formattedDateTime,
  iban,
  distinctRate,
  cryptoCurrency,
  walletAdress
}) => {
  const withdrawalCardView = (): string => {
    const isValidCardLength = isCardValid(withdrawalTo);

    if (isValidCardLength && sectionName === 'Card') return `${withdrawalTo.slice(0, 4)} XXXX XXXX ${withdrawalTo.slice(-4)}`;
    return withdrawalTo;
  }

  if (sectionName === 'Card') {
    return (
      <>
        <div className={ style.contenContainer }>
          <div className={style.transactionDetailsWrapper}>
            <p className={style.transactionDetails}>{t('labels.transaction_details')}</p>
            <div className={style.withdrawalInfo}>
              <div className={style.content}>
                <p className={style.contentTitle}>{`${t('labels.withdrawal')} ${t('labels.amount')}`}</p>
                <p className={style.contentText}>{`${getCurrencySymbol(currency ?? 'USD')}  ${amount}`}</p>
              </div>
              <div className={style.content}>
                <p className={style.contentTitle}>{`${t('labels.date')} / ${t('labels.time')}`}</p>
                <p className={style.contentText}>{formattedDateTime}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={ style.contenContainer }>
          <div className={style.transactionDetailsWrapper}>
            <p className={style.transactionDetails}>{t('labels.payment_method')}</p>
            <div className={style.content}>
              <p className={style.contentTitle}>{t('labels.card')}</p>
              <p className={style.contentText}>{withdrawalCardView()}</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  if (sectionName === 'Bank') {
    return (
      <>
        <div className={ style.contenContainer }>
          <div className={style.transactionDetailsWrapper}>
            <p className={style.transactionDetails}>{t('labels.transaction_details')}</p>
            <div className={style.withdrawalInfo}>
              <div className={style.content}>
                <p className={style.contentTitle}>{`${t('labels.withdrawal')} ${t('labels.amount')}`}</p>
                <p className={style.contentText}>{`${getCurrencySymbol(currency ?? 'USD')}  ${amount}`}</p>
              </div>
              <div className={style.content}>
                <p className={style.contentTitle}>{`${t('labels.date')} / ${t('labels.time')}`}</p>
                <p className={style.contentText}>{formattedDateTime}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={ style.contenContainer }>
          <div className={style.transactionDetailsWrapper}>
            <p className={style.transactionDetails}>{t('labels.payment_method')}</p>
            <div className={style.content}>
              <p className={style.contentTitle}>{t('labels.bank_name')}</p>
              <p className={style.contentText}>{bankName ?? ''}</p>
            </div>
            <div className={style.content}>
              <p className={style.contentTitle}>{'IBAN'}</p>
              <p className={style.contentText}>{iban ?? ''}</p>
            </div>
            <div className={style.content}>
              <p className={style.contentTitle}>{t('labels.currency')}</p>
              <p className={style.contentText}>{distinctRate ?? currency}</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  if (sectionName === 'Crypto') {
    return (
    <>
      <div className={ style.contenContainer }>
        <div className={style.transactionDetailsWrapper}>
          <p className={style.transactionDetails}>{t('labels.transaction_details')}</p>
          <div className={style.withdrawalInfo}>
            <div className={style.content}>
              <p className={style.contentTitle}>{t('labels.cryptocurrency')}</p>
              <p className={style.contentText}>{cryptoCurrency}</p>
            </div>
            <div className={style.content}>
              <p className={style.contentTitle}>{t('labels.amount')}</p>
              <p className={style.contentText}>{amount}</p>
            </div>
            <div className={style.content}>
              <p className={style.contentTitle}>{`${t('labels.date')} / ${t('labels.time')}`}</p>
              <p className={style.contentText}>{formattedDateTime}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={ style.contenContainer }>
          <div className={style.transactionDetailsWrapper}>
            <p className={style.transactionDetails}>{t('labels.payment_method')}</p>
            <div className={style.content}>
              <p className={style.contentTitle}>{t('labels.wallet_address')}</p>
              <p className={style.contentText}>{walletAdress ?? ''}</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={ style.contentContainerNoData }>
      {t('labels.no_such_withdrawal_method')}
    </div>
  )
}
