import React from 'react';
import { Radio } from '@mui/material';
import { styled } from '@mui/material/styles';

interface RadioInterface {
  id?: string
  name?: string
  disabled?: boolean
  checked?: boolean
  value?: string
  selectedValue?: string
  isWithdrawal?: string
  style?: Record<string, string>
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CustomizedRadio = styled(Radio)<{ iswithdrawal: string }>(({ iswithdrawal }) => ({
  color: 'var(--CFD-theme-System-OnSurfaceVariant)',
  display: iswithdrawal === 'true' ? 'none' : 'inline-flex',
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_08)'
  },
  '&.Mui-checked': {
    color: 'var(--CFD-theme-System-Tertiary)',
    '&:hover': {
      backgroundColor: 'var(--CFD-theme-State-layers-tertiary-fixed-dim-opacity_08)'
    }
  },
  '&.Mui-disabled': {
    color: 'var(--CFD-theme-System-OnSurface)',
    opacity: 0.38
  }
}));

export const RadioButtonComponent: React.FC<RadioInterface> = ({ disabled = false, isWithdrawal = 'false', style, ...rest }) => {
  return (
    <CustomizedRadio
      disabled={disabled}
      iswithdrawal={isWithdrawal}
      sx={style}
      {...rest}
    />
  )
}
