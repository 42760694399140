import React, { type ReactNode, type DialogHTMLAttributes, type MouseEvent, type FC, type ReactPortal } from 'react';
import { createPortal } from 'react-dom';
import { useBodyOverflow } from '../../hooks/useBodyOverflow';
import { useAppSelector } from '../../hooks/redux';
import { selectLanguage } from '../../redux/selectors/language';

import style from './TransactionModalContext.module.css';

interface InterfaceTransactionModalContext {
  open: boolean
  setOpen: (el: boolean) => void
  component: ReactNode
}

type TTransactionModalContext = InterfaceTransactionModalContext & DialogHTMLAttributes<HTMLDialogElement>;

const modalRoot = document.getElementById('modal-transaction');

export const TransactionModalContext: FC<TTransactionModalContext> = ({
  setOpen,
  open,
  component,
  ...rest
}): ReactPortal | null => {
  const activeLanguage = useAppSelector(selectLanguage);

  useBodyOverflow(open);

  const handleBackgroundClick = (): void => {
    setOpen(false);
  };

  const stopPropagation = (e: MouseEvent): void => {
    e.stopPropagation();
  };

  if (modalRoot !== null && open) {
    return createPortal(
      <div
        className={ `${style.modalBackground} ${activeLanguage === 'ar' ? style.arabicFont : style.defaultFont}` }
        onClick={ handleBackgroundClick }
      >
        <dialog
          className={ style.dialogWrapper }
          open={ open }
          onClick={ stopPropagation }
          {...rest}
        >
          { component ?? null }
        </dialog>
        </div>,
      modalRoot
    );
  }
  return null;
};
