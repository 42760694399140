import React, { type FC, useRef, useState, useEffect } from 'react';

import { useWindowSize } from '../../hooks/useWindowSize';
import { AdvertisementLabels } from './AdvertisementLabels/AdvertisementLabels';

import styles from './RunningAdvertisementLineComponent.module.css';

export const RunningAdvertisementLineComponent: FC = () => {
  const [screenWidth] = useWindowSize();
  const tickerRef = useRef<HTMLDivElement>(null);
  const [animationKey, setAnimationKey] = useState<number>(0);
  const isMobile = screenWidth <= 800;
  const isTable = (screenWidth <= 1280 && screenWidth >= 600);

  useEffect(() => {
    setAnimationKey(prevKey => prevKey + 1);
  }, []);

  return (
    <div
      className={styles.runningAdsWrapper}
      style={{
        position: isTable ? 'fixed' : 'relative',
        bottom: isTable ? '0' : 'unset'
      }}>
      <div
        key={animationKey}
        className={styles.runningAds}
        style={{
          width: isMobile ? '800px' : `${screenWidth}px`
        }}
        ref={tickerRef}
      >
        <div className={styles.runningAdsText}>
          <AdvertisementLabels />
        </div>
        <div className={styles.runningAdsText}>
          <AdvertisementLabels />
        </div>
      </div>
    </div>
  )
};
