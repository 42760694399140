import React, { type FC } from 'react';
import { TableBody, TableCell, TableRow, styled } from '@mui/material';
import { t } from 'i18next';

import { formatDate } from '../../../../helpers/util';
import { EmptyTableComponent } from '../../../../components';
import { type ITransactionHistory } from '../../../../redux/reducers/transactionsHistory';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { MobileStyledAccountsTableBody } from './MobileStyledAccountsTableBody/MobileStyledAccountsTableBody';

import style from './StyledAccountsTableBody.module.css';

interface AccountTableBodyProps {
  rows: ITransactionHistory[]
  selectedAccount: IAccount | null
}

const StyledTableCell = styled(TableCell)(() => ({
  color: 'var(--CFD-theme-System-OnSurface)',
  fontFamily: 'Arial',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '10px',
  letterSpacing: '0.4px',
  textIndent: '12px',
  '@media (max-width: 905px)': {
    display: 'none'
  },
  borderBottom: '1px solid var(--CFD-theme-System-OutlineVariant)'
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: 'var(--CFD-theme-System-Surface)',
  color: 'var(--CFD-theme-System-OnSurface)',
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_08)'
  }
}));

export const bgStatusColor = {
  Pending: 'var(--CFD-theme-State-layers-secondary-opacity_08)',
  Completed: 'var(--CFD-theme-State-layers-tertiary-opacity_08)',
  Declined: 'var(--CFD-theme-State-layers-error-opacity_08)'
}

export const StyledAccountsTableBody: FC<AccountTableBodyProps> = ({ rows, selectedAccount }) => {
  const [widthScreen] = useWindowSize();

  const isMobile = (widthScreen <= 905);

  const setAmount = (row: ITransactionHistory): string => {
    if (row.type === 'Withdrawal') {
      return `-${row.amount}`;
    }

    if (row.type === 'Exchange') {
      const amount = selectedAccount?.cfdAccountCurrency.symbol === row.asset ? `-${row.amount}` : String(row.amount);

      return amount;
    }

    return String(row.amount)
  }

  return (
    <>
      {isMobile
        ? <MobileStyledAccountsTableBody rows={ rows } setAmount={ setAmount } />
        : (rows.length > 0)
            ? (<TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell>{row.customId}</StyledTableCell>
                    <StyledTableCell>{formatDate(new Date(row.createdAt))}</StyledTableCell>
                    <StyledTableCell>{ t(`transaction_types.${row.type}`) }</StyledTableCell>
                    <StyledTableCell>{setAmount(row)}</StyledTableCell>
                    <StyledTableCell>{row.asset}</StyledTableCell>
                    <StyledTableCell sx={{ color: row.statusColor, textIndent: '0', paddingLeft: '24px' }}>
                      <div className={ style.statusContainer } style={{ backgroundColor: bgStatusColor[row.status as keyof typeof bgStatusColor] }}>
                        { t(`transaction_statuses.${row.status}`) }
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>)
            : (<EmptyTableComponent columnCount={6} />)}
    </>
  )
}
