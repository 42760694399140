import React, { type FC } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { t } from 'i18next';

import style from './HeaderModalTransferFunds.module.css';

interface InterfaceHeaderModalTransferFunds {
  setOpen: (el: boolean) => void
  handleStateAfterTransfer: () => void
  step: number
}

export const HeaderModalTransferFunds: FC<InterfaceHeaderModalTransferFunds> = ({ setOpen, handleStateAfterTransfer, step }) => {
  const handleBtnClick = (): void => {
    if (step !== 1) handleStateAfterTransfer();
    setOpen(false)
  }
  return (
    <header className={ style.dialogHeader }>
      <div className={ style.dialogHeaderContainer }>
        <div className={ style.dialogHeaderContent }>
          <h3 className={ style.dialogHeaderTitle }>{t('labels.transfer_funds')}</h3>
        </div>
        <div className={ style.dialogHeaderBtnContainer }>
          <button className={ style.btn } onClick={ handleBtnClick }>
          <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
          </button>
        </div>
      </div>
    </header>
  )
}
