export enum ProfileElementHeight {
  PaddingTop = 20,
  Footer = 64,
  Header = 52,
  Gap = 24,
  TableHeader = 117,
  BalanceChartHeader = 55,
  BalanceChartSelect = 50,
  BalanceChartMargin = 20,
  BalanceChartFooter = 40,
  TradingChartHeader = 100,
  TradingChartPaddings = 10,
  StatisticHeight = 292
}
