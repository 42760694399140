import React, { type FC, type ChangeEvent, useContext } from 'react';
import { RadioGroup, FormControlLabel, FormControl, styled, Typography } from '@mui/material';
import { t } from 'i18next';
import DoneIcon from '@mui/icons-material/Done';
import { ButtonComponent, RadioButtonComponent } from '../../../../../components';
import { VisaIcon } from '../../../../../components/icons/VisaIcon';
import { MasterCardIcon } from '../../../../../components/icons/MasterCardIcon';
import { deleteCard, type ICard } from '../../../../../redux/reducers/withdrawalMethods';
import { useAppDispatch } from '../../../../../hooks/redux';
import { DialogContext } from '../../../context/DialogContextProvider';
import binIcon from '../../../../../assets/images/delete_24px.svg';

import style from './SavedCardWithdrawalOptions.module.css';

interface InterfaceSavedCardWithdrawalOptions {
  options: ICard[]
  selectedOptions: string
  setSelectedOptions: (el: string) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: 'var(--CFD-theme-Surface-3)',
  borderRadius: '4px'
}))

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center'
}))

export const SavedCardWithdrawalOptions: FC<InterfaceSavedCardWithdrawalOptions> = ({ options, selectedOptions, setSelectedOptions, onChange }) => {
  const dispatch = useAppDispatch()
  const { showDialog, hideDialog } = useContext(DialogContext);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedOptions(event.target.value);
  };

  const handleDeleteBtn = (cardId: string): void => {
    dispatch(deleteCard(cardId));
    setSelectedOptions('');
    hideDialog();
  }

  return (
    <StyledFormControl>
      <StyledRadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={ selectedOptions ?? '' }
        onChange={ onChange ?? handleChange }
      >
        {options.map((el, index) => {
          const border = (
            options.length === 1
              ? '4px'
              : index === 0
                ? '4px 4px 0 0'
                : index === options.length - 1
                  ? '0 0 4px 4px'
                  : '0');
          return <FormControlLabel
            sx={{
              display: 'flex',
              border: '1px solid var(--CFD-theme-System-OutlineVariant)',
              backgroundColor: selectedOptions === el._id ? 'var(--CFD-theme-Surface-5)' : 'var(--CFD-theme-System-Surface)',
              borderRadius: `${border}`,
              padding: '12px 12px 12px 8px',
              gap: '8px',
              marginLeft: '0',
              marginRight: '0',
              width: '100%',
              '&:hover': {
                backgroundColor: 'var(--CFD-theme-State-layers-on-surface-variant-opacity_08)'
              }
            }}
            key={ el._id }
            value={ el._id }
            control={
              <>
                <RadioButtonComponent
                  checked={selectedOptions === el._id}
                  onChange={ onChange ?? handleChange }
                  value={el._id ?? ''}
                  isWithdrawal='true'
                />
                {selectedOptions === el._id
                  ? (<DoneIcon sx={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }} />)
                  : (<span className={ style.uncheckedSpan }/>)
                }
              </>
            }
            label={
              <Typography component='div' sx={{ width: '100%' }}>
                <div className={ style.assetsCheckContainer }>
                  <div className={ style.assetsContainer }>
                    <p className={ style.assetsCheckContainerTitle }>{ el.card }</p>
                  </div>
                  <div className={ style.logoBinWrapper }>
                  { el.card[0] === '4' && <div className={ style.visaContainer }><VisaIcon /></div> }
                  { el.card[0] === '5' && <MasterCardIcon /> }
                  {/* { (el.card[0] !== '4' && el.card[0] !== '5') &&
                    <div className={ style.cardIcons }>
                      <VisaIcon />
                      <MasterCardIcon />
                    </div>
                  } */}
                  {
                    <button className={ style.binBtn } onClick={(e) => {
                      e.preventDefault()
                      showDialog({
                        headerContent: <div className={style.dialogHeader}>
                          {t('accounts_page.delete_withdrawal_method')}
                        </div>,
                        bodyContent: <div className={style.dialogBody}>
                          {t('accounts_page.body_ask_question')}
                        </div>,
                        footerContent: <div className={style.dialogFooter}>
                          <ButtonComponent btnstyle='primary' variant="text" onClick={hideDialog}>
                            {t('accounts_page.cancel')}
                          </ButtonComponent>
                          <ButtonComponent btnstyle='primary' variant="text" onClick={() => { handleDeleteBtn(el._id); }}>
                            {t('accounts_page.delete')}
                          </ButtonComponent>
                        </div>
                      });
                    }}>
                      <svg className={ style.binIconColor }>
                        <use xlinkHref={`${binIcon}#binIcon`} />
                      </svg>
                    </button>
                  }
                  </div>
                </div>
              </Typography>
            }
          />
        })}
      </StyledRadioGroup>
    </StyledFormControl>
  )
}
