import React from 'react';
import { type IOrder } from '../../../../../../redux/reducers/orders';
import { type IColumn } from '../../../../../../interfaces/ColumnInterface';
import { getIconByName } from '../../../../helper';
import { toFixed, formatDate, getCurrencySymbol } from '../../../../../../helpers/util';
import { t } from 'i18next';
import styles from '../DataTable.module.css';
import { type IMarketToAssetSocketData } from '../../../../../../interfaces';

export const getHistoryOrderSchema = (
  activeAccountSymbol: string,
  currencyRates: Record<string, number>,
  marketToAssets: IMarketToAssetSocketData[],
  hiddenPlatformColumns: string[]
): IColumn[] => {
  const historyOrderSchema: IColumn[] = [
    {
      name: t('labels.num'),
      cell: (row: IOrder, idx: number) => String(idx + 1),
      defaultName: 'No.'
    },
    {
      name: t('labels.symbol'),
      defaultName: 'Symbol',
      align: 'left',
      cell: (row: IOrder): React.ReactNode => (
        <div className={styles.symbolCell}>
          <img src={getIconByName(row.setting?.label ?? '')} alt={row.setting?.label}/>
          <span>{row.setting?.label}</span>
        </div>
      )
    },
    {
      name: t('labels.id'),
      defaultName: 'Id',
      align: 'left',
      cell: (row: IOrder): string => (String(row.customId))
    },
    {
      name: t('labels.type'),
      defaultName: 'Type',
      align: 'left',
      cell: (row: IOrder): React.ReactNode => (row.tradeType === 1 ? <span className={styles.typeLabelBuy}>{t('labels.buy')}</span> : <span className={styles.typeLabelSell}>{t('labels.sell')}</span>)
    },
    {
      name: t('labels.value'),
      defaultName: 'Value',
      align: 'left',
      cell: (row: IOrder): string => (String(toFixed((row.position ?? 0) * (row.tradeStartPrice ?? 0), 2)))
    },
    {
      name: t('labels.open_price'),
      defaultName: 'Open Price',
      align: 'left',
      cell: (row: IOrder): string => (`${toFixed(row.tradeStartPrice ?? 0, 2)} $`)
    },
    {
      name: t('labels.open_time'),
      defaultName: 'Open Time',
      align: 'left',
      cell: (row: IOrder): string => {
        const date = new Date(row.timeOpened ?? '');

        return formatDate(date);
      }
    },
    {
      name: t('labels.close_price'),
      defaultName: 'Close Price',
      align: 'left',
      cell: (row: IOrder): string => (`${toFixed(row.tradeEndPrice ?? 0, 2)} $`)
    },
    {
      name: t('labels.close_time'),
      defaultName: 'Close Time',
      align: 'left',
      cell: (row: IOrder): string => {
        const date = new Date(row.closedAt ?? '');

        return formatDate(date);
      }
    },
    {
      name: t('labels.commission'),
      defaultName: 'Commission',
      align: 'left',
      cell: (row: IOrder): string => {
        const valueInUsd = toFixed(row.userInvestedAmount ?? 0, 2)

        if (activeAccountSymbol !== undefined) {
          // convert to selected currency
          const value = valueInUsd * currencyRates[activeAccountSymbol]
          const commission = row.commission ?? 0

          const commissionAmount = value * (commission / 100)
          return `${toFixed(commissionAmount, 2)} ${getCurrencySymbol(activeAccountSymbol)}`
        }

        return `0 ${getCurrencySymbol(activeAccountSymbol)}`;
      }
    },
    {
      name: t('labels.swap'),
      defaultName: 'Swap',
      align: 'left',
      cell: (row: IOrder): string => {
        const valueInUsd = +(row.userInvestedAmount ?? 0)

        if (activeAccountSymbol !== undefined) {
          // convert to selected currency
          const value = valueInUsd * currencyRates[activeAccountSymbol]
          const actualSwap = row.endSwap ?? 0

          const swapFee = toFixed((value * (actualSwap / 100)), 2)
          return `${swapFee} ${getCurrencySymbol(activeAccountSymbol)}`;
        }

        return `0 ${getCurrencySymbol(activeAccountSymbol)}`;
      }
    },
    {
      name: t('labels.pnl'),
      defaultName: 'PnL',
      align: 'left',
      cell: (row: IOrder): string => `${toFixed(row.exitPrice ?? 0, 3)} ${getCurrencySymbol(activeAccountSymbol)}`
    }
  ];

  return historyOrderSchema.filter(({ defaultName }) => {
    if (typeof defaultName === 'string') {
      return !hiddenPlatformColumns.includes(defaultName);
    }

    return true;
  });
}
