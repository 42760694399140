import React, { type FC } from 'react';

import {
  SectionSuccessModal,
  ButtonComponent
} from '../../../../components';

import style from './SuccessWithdrawalSection.module.css';
import { t } from 'i18next';
import { SuccessWithdrawalCell } from './SuccessWithdrawalCell/SuccessWithdrawalCell';

export type sectionNameType = 'Crypto' | 'Bank' | 'Card' | 'Other';

interface InterfaceSuccessWithdrawalSection {
  title: string
  onClick: () => void
  withdrawalTo?: string
  amount: string
  currency: string | null
  bankName?: string
  bankCurrency?: string
  sectionName: sectionNameType
  iban?: string
  distinctRate?: string
  cryptoCurrency?: string
  totalWithdrawals?: string
  walletAdress?: string
}

export const SuccessWithdrawalSection: FC<InterfaceSuccessWithdrawalSection> = ({
  title,
  onClick,
  amount,
  withdrawalTo,
  sectionName,
  currency,
  bankName,
  bankCurrency,
  iban,
  distinctRate,
  cryptoCurrency,
  totalWithdrawals,
  walletAdress
}) => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const datePart = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

  const timePart = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  }).format(now)

  const formattedDateTime = `${datePart} / ${timePart}`;

  return (
   <>
   <div className={ style.wrapper }>
    <div className={ style.sucessSection }>
      <SectionSuccessModal title={ title }/>
      <SuccessWithdrawalCell
        amount={ amount }
        withdrawalTo={ withdrawalTo ?? '' }
        sectionName={ sectionName }
        currency={ currency }
        bankName={ bankName }
        bankCurrency={bankCurrency}
        formattedDateTime={ formattedDateTime }
        iban={ iban }
        distinctRate={ distinctRate }
        cryptoCurrency={ cryptoCurrency }
        totalWithdrawals={ totalWithdrawals }
        walletAdress={ walletAdress }
      />
    </div>
   </div>
    <footer className={ style.footer }>
      <div className={ style.btnContainer }>
        <ButtonComponent onClick={ onClick } variant='outlined'>
          <span className={ style.btnText }>
            {t('labels.view_account')}
          </span>
        </ButtonComponent>
      </div>
    </footer>
   </>
  )
}
