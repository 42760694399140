import React, { type FC } from 'react';
import { t } from 'i18next';

import { useAppSelector } from '../../../../hooks/redux';
import { LogoIcon } from '../../../icons/LogoIcon';
import { FabComponent } from '../../../../components';
import reciveDeposit from '../../../../assets/images/recive_deposit.svg';

import style from '../HeaderLiveChatComponent.module.css';

interface IMobileHeaderLiveChatComponent {
  setChatOpen: (el: boolean) => void
  setOpenDeposit: (el: boolean) => void
}

export const MobileHeaderLiveChatComponent: FC<IMobileHeaderLiveChatComponent> = ({ setChatOpen, setOpenDeposit = () => {} }) => {
  const { firstName } = useAppSelector((state) => state.user);

  const depositBtn = (): void => {
    setOpenDeposit(true);
    setChatOpen(false)
  }

  return (
    <>
    <div className={ style.headerLiveMobileChat }>
        <div className={ style.headerLiveMobileLogo }>
          <LogoIcon />
        </div>
        <div className={ style.headerLiveMobileOptions }>
          <div className={ style.headerLiveMobileBtn }>
            <FabComponent variant="extended" size='small' onClick={ depositBtn }>
              <svg className={ style.svg }>
                <use xlinkHref={`${reciveDeposit}#reciveDeposit`}/>
              </svg>
              <span className={style.headerTitle}>{t('header.deposit_button_title')}</span>
            </FabComponent>
          </div>
          {
            (firstName !== undefined && firstName.length > 0) && (
              <div className={ style.headerLiveMobileUserIcon }>
                { firstName[0] }
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}
