import React, { type FC, type MouseEvent } from 'react';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { t } from 'i18next';

import { LanguageOptions } from '../LanguageOptions/LanguageOptions';
import { ButtonComponent } from '../../ButtonComponent/ButtonComponent';

import style from './LanguageSectionComponent.module.css';

interface ILanguageSectionComponent {
  handleGoBacKFromLanguageSelect: () => void
  handleCloseLanguageSelect: () => void
  handleLanguageOptionsChange: (langCode: string) => void
  handleSaveLanguageBtn: () => void
  stopPropagation: (event: MouseEvent) => void
}

export const LanguageSectionComponent: FC<ILanguageSectionComponent> = ({
  handleGoBacKFromLanguageSelect,
  handleCloseLanguageSelect,
  handleLanguageOptionsChange,
  handleSaveLanguageBtn,
  stopPropagation
}) => {
  return (
    <div
      className={ style.menuWrapper }
      onClick={ stopPropagation }
    >
    <div className={ style.menuHeader } onClick={ stopPropagation }>
      <button className={ style.btnContainer } onClick={ handleGoBacKFromLanguageSelect }>
        <ArrowBackOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
      </button>
      <p className={ style.headerTitle }>{t('labels.language')}</p>
      <button className={ style.btnContainer } onClick={ handleCloseLanguageSelect }>
        <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
      </button>
    </div>
    <div className={ style.menuBodyLanguage }>
      <LanguageOptions onChange={ handleLanguageOptionsChange } />
    </div>
    <div className={ style.footer }>
      <div className={ style.btnFooterContainer }>
        <ButtonComponent onClick={handleSaveLanguageBtn} variant='outlined'>{t('labels.save')}</ButtonComponent>
      </div>
    </div>
  </div>
  )
}
