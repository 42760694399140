import React, { type FC } from 'react';

import { useAppSelector } from '../../../../hooks/redux';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { MobileAccountsTableNavigation } from './MobileAccountsTableNavigation/MobileAccountsTableNavigation';
import { ButtonAccountsTableNavigation } from './ButtonAccountsTableNavigation/ButtonAccountsTableNavigation';

import style from './AccountsTableNavigation.module.css';

interface IProps {
  selectedAccount: IAccount | null
  setSelectedAccount: (el: IAccount | null) => void
}

export const AccountsTableNavigation: FC<IProps> = ({ selectedAccount, setSelectedAccount }) => {
  const [screenWidth] = useWindowSize();
  const accounts = useAppSelector((state) => state.accounts);

  const isMobile = screenWidth < 600;

  return (
    <>
    {
      !isMobile
        ? (
          <div className={ style.headerWrapper }>
            <div className={ style.header }>
              {
                accounts.map((account) => (
                  <ButtonAccountsTableNavigation
                    key={ account._id }
                    account={ account }
                    selectedAccount={ selectedAccount }
                    setSelectedAccount={ setSelectedAccount }
                  />
                ))
              }
            </div>
          </div>
          )
        : (
            <MobileAccountsTableNavigation
              accounts={ accounts }
              accountOption={ selectedAccount?._id ?? '' }
              setAccountOption={ setSelectedAccount }
            />
          )
    }
    </>
  )
}
