import React, { useContext, useEffect, type FC } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { ButtonComponent, ProgressComponent, useSnackbar } from '../../../../components';
import { VerificationContent } from '../VerificationContent/VerificationContent';
import { TwoFAContent } from '../2FAContent/TwoFAContent';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { deleteVerificationRequest, fetchUserVerificationData } from '../../../../redux/reducers/kycVerification';
import { kycVerificationSelector } from '../../../../redux/selectors/kycVerification';
import { fetchUser2FAData, setStep2FA } from '../../../../redux/reducers/twoFactorAuthentication';
import { selectIsUserDataAvailable } from '../../../../redux/selectors/user';
import { t } from 'i18next';
import style from './CompleteProfileSettings.module.css';
import { getKycTypes } from '../../../../redux/reducers/kycTypes';
import { selectKycTypes, selectKycTypesError, selectKycTypesLoading } from '../../../../redux/selectors/kycTypes';
import { profileSettingsSelector } from '../../../../redux/selectors/profileSettings';
import { GlobalDialogContext } from '../../../../context/GlobalDialogContext/GlobalDialogContextProvider';
import Delete2FA from '../2FAContent/Delete2FADialog/Delete2FA';

interface InterfaceCompleteProfileSettingsProps {
  setOpenVerificationModal: (el: boolean) => void
  setOpen2FAModal: (el: boolean) => void
}

export const CompleteProfileSettings: FC<InterfaceCompleteProfileSettingsProps> = ({ setOpen2FAModal, setOpenVerificationModal }) => {
  const dispatch = useAppDispatch()
  const { handleOpen } = useSnackbar();
  const { showDialog, hideDialog } = useContext(GlobalDialogContext);

  const {
    isIdCardVerified,
    isCreditCardVerified,
    isSelfieVerified,
    isAddressVerified
  } = useAppSelector(profileSettingsSelector);

  const verificationStatusMap = new Map<string, boolean>([
    ['verifyDocument', isIdCardVerified],
    ['verifyCard', isCreditCardVerified],
    ['verifySelfie', isSelfieVerified],
    ['verifyAddress', isAddressVerified]
  ]);

  const isTypeVerified = (typeName: string): boolean => {
    return verificationStatusMap.get(typeName) ?? false;
  };

  const requiredKycTypes = useAppSelector(selectKycTypes);
  const kycTypesLoading = useAppSelector(selectKycTypesLoading);
  const kycTypesError = useAppSelector(selectKycTypesError);

  const isUserDataAvailable = useAppSelector(selectIsUserDataAvailable);
  const { isUserHaveKYC: VerificationComplete } = useAppSelector(kycVerificationSelector);

  const isVerifyStarted = requiredKycTypes.some((type) => (isTypeVerified(type.name)));

  const handleKycEditIcon = (): void => {
    setOpenVerificationModal(true);
  }

  const handle2FAEditIcon = (): void => {
    dispatch(setStep2FA(1));
    setOpen2FAModal(true);
  }

  const handleOpen2FA = (): void => {
    dispatch(setStep2FA(1));
    setOpen2FAModal(true);
  }

  const handleKycBinIcon = (): void => {
    dispatch(deleteVerificationRequest(handleOpen));
  }

  const handle2FABinIcon = (): void => {
    showDialog({ headerContent: <Delete2FA hideDialog={hideDialog} /> });
  }

  useEffect(() => {
    if (isUserDataAvailable) {
      dispatch(fetchUserVerificationData())
      dispatch(fetchUser2FAData())
      dispatch(getKycTypes());
    }
  }, [isUserDataAvailable])

  if (kycTypesLoading) {
    return <ProgressComponent type='circular' />;
  }

  if (kycTypesError) {
    handleOpen({
      message: 'Kyc/Aml Types Error',
      actionText: '',
      severity: 'error'
    })
  }

  return (
    <>
        <div className={style.settingsFirstSectionContentContainer}>
            <div className={style.settingsFirstSectionContent}>
                <h4 className={style.settingsFirstSectionContentTitle}>{t('profile_page.settings.complete_profile.titles.identity_verifications')}</h4>
                {/* <TooltipSmallComponent content='' disableFocusListener disableTouchListener placement='left' arrow={ false }> */}
                <InfoOutlinedIcon sx={{ color: 'var(--CFD-theme-System-Error)', marginRight: '14px' }}/>
                {/* </TooltipSmallComponent> */}
            </div>
            <p className={style.settingsFirstSectionContentText}>
                {t('profile_page.settings.complete_profile.identity_verifications.subtitle')}
            </p>
            <div className={style.settingsStatusContainer}>
                {requiredKycTypes.map((type) => (
                    <div key={type.name}
                         className={`${style.statusText} ${isTypeVerified(type.name) ? style.successDocs : style.unSuccessDocs}`}>
                        {isTypeVerified(type.name)
                          ? <DoneOutlinedIcon fontSize='small'/>
                          : <RemoveOutlinedIcon fontSize='small'/>}
                        {t(`profile_page.settings.complete_profile.identity_verifications.statuses.${type.name}`)}
                    </div>
                ))}
            </div>

            <div className={style.settingsFirstSectionContentBtnContainer}>
                <div className={style.btnContainer}>
                    {
                        !isVerifyStarted && (
                            <ButtonComponent
                                variant='text'
                                onClick={() => {
                                  setOpenVerificationModal(true);
                                }}
                            >
                                {t('profile_page.settings.complete_profile.identity_verifications.verify_button_text')}
                            </ButtonComponent>
                        )
                    }
                </div>
            </div>
            {
                VerificationComplete && <VerificationContent
                    handleBinIcon={handleKycBinIcon}
                    handleEditIcon={handleKycEditIcon}
                />
            }
        </div>
        <div className={style.settingsSecondSectionContentContainer}>
            <div className={style.settingsFirstSectionContent}>
                <h4 className={style.settingsFirstSectionContentTitle}>{t('profile_page.settings.complete_profile.titles.google_2fa')}</h4>
                {/* <TooltipSmallComponent content='Support Text' disableFocusListener disableTouchListener placement='left' arrow={ false }> */}
                <InfoOutlinedIcon sx={{ color: 'var(--CFD-theme-System-Error)', marginRight: '14px' }} />
                {/* </TooltipSmallComponent> */}
            </div>
            <p className={ style.settingsSecondSectionContentText }>
                {t('profile_page.settings.complete_profile.google_2fa.subtitle')}
            </p>
            <div className={ style.settingsSecondSectionContentBtnContainer }>
                <div className={ style.btnSecondContainer}>
                    <ButtonComponent variant='text' onClick={handleOpen2FA}>
                        {t('profile_page.settings.complete_profile.google_2fa.2fa_button_text')}
                    </ButtonComponent>
                </div>
            </div>
            <TwoFAContent
                handleBinIcon={handle2FABinIcon}
                handleEditIcon={handle2FAEditIcon}
            />
        </div>
    </>
  )
}
