import React, { type FC } from 'react';
import { t } from 'i18next';

import BadgeSelectedIcon from '../../../../../assets/images/Badge_selected_account.svg';
import { type IAccount } from '../../../../../redux/reducers/accounts';
import { getAccountIconBySymbol } from '../../../../../helpers/util';

import style from './ButtonAccountsTableNavigation.module.css';

interface IButtonAccountsTableNavigation {
  selectedAccount: IAccount | null
  setSelectedAccount: (el: IAccount | null) => void
  account: IAccount
}

export const ButtonAccountsTableNavigation: FC<IButtonAccountsTableNavigation> = ({ selectedAccount, setSelectedAccount, account }) => {
  return (
    <button
      className={ selectedAccount?._id === account._id ? `${style.headerAccountsWrapper} ${style.active}` : `${style.headerAccountsWrapper}`}
      onClick={() => { setSelectedAccount(account) }}
    >
      <img src={ getAccountIconBySymbol(account?.cfdAccountCurrency?.symbol ?? '') } className={ style.img }/>
      <p className={ style.headerAccountsParagraph }>{`${account?.type?.name ?? ''} ${t('labels.account')} ${account?.cfdAccountCurrency?.symbol ?? ''}`}</p>
      {
        account?.isActive &&
          <svg className={ style.svg }>
            <use xlinkHref={`${BadgeSelectedIcon}#badgeSelectedAccount`}/>
          </svg>
      }
    </button>
  )
}
