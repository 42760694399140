import React from 'react';
import { DefaultLogo } from '../../../../components/icons/DefaultLogo';
import styles from './Header.module.css'

export const Header: React.FC = (): JSX.Element => {
  const landingUrl = process.env.REACT_APP_LANDING_URL ?? '';

  return (
    landingUrl.length > 0
      ? (
            <a className={styles.header} href={landingUrl} target='_blank' rel="noreferrer">
                <DefaultLogo />
            </a>
        )
      : (
            <div className={styles.header}>
                <DefaultLogo />
            </div>
        )
  )
}
