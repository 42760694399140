import React, { type FC, useState, type ReactElement, useEffect } from 'react';

import { WithdrawalHeaderComponent } from './WithdrawalHeaderComponent/WithdrawalHeaderComponent';
import { WithdrawalSubHeaderComponent } from './WithdrawalSubHeaderComponent/WithdrawalSubHeaderComponent';
import { CardWithdrawSection } from './CardWithdrawalSection/CardWithdrawalSection';
import { BankWithdrawSection } from './BankWithdrawalSection/BankWithdrawalSection';
import { CryptoWithdrawSection } from './CryptoWithdrawalSection/CryptoWithdrawalSection';
import { type IAccount } from '../../../redux/reducers/accounts';
import { setShowFooter } from '../../../redux/reducers/showFooter';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useGetWithdrawalTypesQuery } from '../../../redux/api/withdrawalTypesApi';
import { type WithdrawalType } from '../../../redux/reducers/withdrawalTypes';
import { useSnackbar, EmptyTableComponent } from '../../../components';
import { handleError } from '../../../helpers';
import { useTranslation } from 'react-i18next';

import style from './WithdrawalComponent.module.css';

interface InterfaceWithdrawalComponent {
  setOpen: (el: boolean) => void
  selectedAccount: IAccount | null
}

export type TypeSubHeaderWithdrawalsOptions = 'Crypto' | 'Card' | 'Bank'

const WithdrawalNodes = {
  Card: CardWithdrawSection,
  Bank: BankWithdrawSection,
  Crypto: CryptoWithdrawSection
};

type OptionsType = Array<{ ticker: TypeSubHeaderWithdrawalsOptions }>

export const WithdrawalComponent: FC<InterfaceWithdrawalComponent> = ({ setOpen, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [withdrawalOptions, setWithdrawalOptions] = useState<OptionsType>([]);

  const [selectedAsset, setSelectedAsset] = useState<TypeSubHeaderWithdrawalsOptions | null>(null);
  const [step, setStep] = useState<number>(1);
  const [screenWidth] = useWindowSize();
  const { showFooter } = useAppSelector((state) => state.showFooter);

  const isTable = screenWidth < 904;

  const { handleOpen } = useSnackbar();

  const {
    data: withdrawalData,
    error: withdrawalError,
    isLoading: isWithdrawalLoading
  } = useGetWithdrawalTypesQuery({});

  if (withdrawalError !== undefined) {
    handleError({
      isError: true,
      error: 'Some error happened',
      skipToast: true
    }, handleOpen)
  }

  useEffect(() => {
    const isDataLoadedSuccess = withdrawalData !== undefined && withdrawalData.success === true;
    const isWithdrawalSuccessful = withdrawalError === undefined;

    if (isDataLoadedSuccess && isWithdrawalSuccessful && !isWithdrawalLoading) {
      const options: OptionsType = withdrawalData.data
        .map(({ name }: WithdrawalType) => ({ ticker: name }));

      setWithdrawalOptions(options)
    }
  }, [withdrawalData, withdrawalError, isWithdrawalLoading]);

  useEffect(() => {
    const isAssetNotSelected = selectedAsset === null;
    const hasWithdrawalOptions = Array.isArray(withdrawalOptions) && withdrawalOptions.length > 0;

    if (isAssetNotSelected && hasWithdrawalOptions) {
      setSelectedAsset(withdrawalOptions[0].ticker);
    }
  }, [withdrawalOptions, selectedAsset]);

  const isMobile = screenWidth <= 599;

  useEffect(() => {
    if (isMobile && showFooter) dispatch(setShowFooter(false));
  }, []);

  const renderSelectedWithdrawalForm = (): ReactElement => {
    if (selectedAsset !== null) {
      const Component = WithdrawalNodes[selectedAsset];
      const props = { setOpen, step, setStep, selectedAccount };

      return <Component {...props} />;
    }

    return (
      <div className={style.noDataRow}>
        <EmptyTableComponent
          mainText={t('withdrawal_modal.no_data.main_text')}
          subText={t('labels.contact_manager')}
          columnCount={1}
        />
      </div>
    ); ;
  };

  const mobileBottomPadding = {
    Crypto: 35,
    Bank: 35,
    Card: 0
  }

  return (
    <div className={ style.mainWrapper }>
        <WithdrawalHeaderComponent setOpen={ setOpen } title={`${t('labels.withdrawal')} ${selectedAccount?.cfdAccountCurrency.symbol ?? ''}`}/>
        <div
          className={ style.bodywrapper }
          style={{
            maxHeight: isTable
              ? `calc(100dvh - ${mobileBottomPadding[selectedAsset ?? 'Card']}px - 120px)`
              : `calc(100vh - ${mobileBottomPadding[selectedAsset ?? 'Card']}px - 120px)`
          }}
        >
          { step === 1 && selectedAsset !== null
            ? <WithdrawalSubHeaderComponent options={ withdrawalOptions } selectedOptions={ selectedAsset } setSelectedOptions={ setSelectedAsset }/>
            : null
          }
          { renderSelectedWithdrawalForm() }
        </div>
    </div>
  )
}
