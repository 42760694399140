import React, { type FC, useState, type MouseEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { t } from 'i18next';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useTheme } from '../../components';
import { AuthData } from '../../auth/AuthWrapper';
import { useWindowSize } from '../../hooks/useWindowSize';
import { setShowFooter } from '../../redux/reducers/showFooter';
import { selectLanguage, selectLanguages } from '../../redux/selectors/language';
import { setLanguage } from '../../redux/reducers/language';
import { socket } from '../../web/socket'
import { useBodyOverflow } from '../../hooks/useBodyOverflow';
import { selectUserProfileImage } from '../../redux/selectors/user';
import { LanguageSectionComponent } from './LanguageSectionComponent/LanguageSectionComponent';

import style from './MenuSettingsComponent.module.css';

export interface InterfaceLanguageOption { language: string, code: string, icon: any }

interface InterfaceMenuSettingsComponent {
  setOpenMenu: (el: boolean) => void
}

export const MenuSettingsComponent: FC<InterfaceMenuSettingsComponent> = ({ setOpenMenu }) => {
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();
  const [screenWidth] = useWindowSize();
  const profileImage: string | null = useAppSelector(selectUserProfileImage);

  const [openLanguage, setOpenLanguage] = useState<boolean>(false);
  const { email, _id: userId, firstName, lastName } = useAppSelector((state) => state.user);
  const activeLanguage = useAppSelector(selectLanguage);
  const languages = useAppSelector(selectLanguages);

  const isMobile = screenWidth <= 600;
  useBodyOverflow(isMobile);

  const imageLink = `${process.env.REACT_APP_IMAGES_URL}${profileImage}`;

  const [localLang, setLocalLang] = useState(activeLanguage)

  const handleLanguageOptionsChange = (langCode: string): void => {
    setLocalLang(langCode)
  }

  const handleSaveLanguageBtn = (): void => {
    i18n.changeLanguage(localLang)
    dispatch(setShowFooter(true))
    dispatch(setLanguage(localLang))
  }

  const handleLanguageBtn = (): void => {
    setOpenLanguage(true);
  }

  useEffect(() => {
    const isMobileSize = screenWidth <= 600;
    if (isMobileSize) dispatch(setShowFooter(false));
    else dispatch(setShowFooter(true));
  }, [screenWidth]);

  const handleGoBacKFromLanguageSelect = (): void => {
    setOpenLanguage(false);
  }

  const handleCloseLanguageSelect = (): void => {
    dispatch(setShowFooter(true))
    setOpenMenu(false);
  }

  const activeLanguageData = languages.find((l) => l.code === activeLanguage) ?? languages.find((l) => l.code === 'en')

  const { logout } = AuthData();

  const logOutBtn = (): void => {
    if (userId === undefined) return;

    const isSessionToken = sessionStorage.getItem('token');

    if (!isSessionToken) {
      socket.emit('stopStreaming', { userId });
    }
    logout(userId);
  }

  const stopPropagation = (event: MouseEvent): void => {
    event.stopPropagation();
  };

  const profileBtnHandler = (): void => {
    navigate('/profile');
    if (isMobile) setOpenMenu(false);
    dispatch(setShowFooter(true));
  }

  const themeToggleHandler = (): void => {
    toggleTheme();
  }

  const closeSettingBtn = (): void => {
    setOpenMenu(false);
    dispatch(setShowFooter(true));
  }

  return (
      <div className={ style.background } onClick={() => { setOpenMenu(false); } }>
        <div
          className={ style.container }
        >
          { !openLanguage
            ? <>
                <div
                    className={ style.menuWrapper }
                    onClick={ stopPropagation }
                  >
                  <div className={ style.menuMobileHeader }>
                    <p className={ style.menuHeaderNavigationText }>{ t('profile_page.labels.settings') }</p>
                    <button className={ style.btnContainer } onClick={ closeSettingBtn }>
                      <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                    </button>
                  </div>
                  <div className={ style.menuHeader }>
                    <div className={ style.menuHeaderAccount }>
                      <div className={ style.menuHeaderAccountInfoContainer }>
                        {
                          profileImage === null
                            ? firstName !== undefined && firstName.length > 0 && (
                                <div className={style.userIcon}>
                                  {firstName[0]}
                                </div>
                            )
                            : <Avatar sx={{ width: 40, height: 40 }} alt={firstName} src={imageLink} />
                        }
                        <div className={ style.menuHeaderAccountInfo }>
                          <p className={ style.menuHeaderAccountInfoTitle }>
                            { `${firstName} ${lastName}` }
                          </p>
                          <p className={ style.menuHeaderAccountInfoEmail }>{ email }</p>
                        </div>
                      </div>
                      <button className={ style.menuHeaderAccountLink } onClick={ profileBtnHandler }>
                        <OpenInNewIcon sx={{
                          width: '16px',
                          height: '16px',
                          color: 'var(--CFD-theme-System-Primary)'
                        }}
                        />
                        <p className={ style.menuHeaderAccountLinkText }>{t('labels.profile')}</p>
                      </button>
                    </div>
                  </div>
                  <div className={ style.menuBody }>
                    <div className={ style.mainMenuContainerLanguage }>
                      <p className={ style.mainMenuText }>{t('labels.appearance')}</p>
                      <div className={ style.mainMenu }>
                        <button className={ style.menuCell } onClick={ themeToggleHandler }>
                          { theme === 'light'
                            ? <DarkModeIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}/>
                            : <LightModeIcon style={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}/> }
                          <p className={ style.menuCellText }>{theme === 'light' ? 'Dark' : 'Light'}</p>
                        </button>
                      </div>
                    </div>
                    <div className={ style.mainMenuContainer }>
                      <p className={ style.mainMenuText }>{t('labels.language')}</p>
                      <div className={ style.mainMenu }>
                        <button className={ style.languageContainer } onClick={ handleLanguageBtn }>
                          <span className={ style.languageTextContainer }>
                            <img className={ style.languageIcon } src={activeLanguageData?.icon} alt="#" />
                            <p className={ style.menuCellText }>{activeLanguageData?.language}</p>
                          </span>
                          <ArrowRightOutlinedIcon style={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                        </button>
                      </div>
                    </div>
                    <div className={ style.mainMenuContainer }>
                      <p className={ style.mainMenuText }>{ email }</p>
                      <div className={ style.mainMenu }>
                        <button className={ style.menuCell } onClick={ logOutBtn }>
                          <LogoutOutlinedIcon style={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                          <p className={ style.menuCellText }>{t('labels.log_out')}</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            : <LanguageSectionComponent
                handleGoBacKFromLanguageSelect={ handleGoBacKFromLanguageSelect }
                handleCloseLanguageSelect={ handleCloseLanguageSelect }
                handleLanguageOptionsChange={ handleLanguageOptionsChange }
                handleSaveLanguageBtn={ handleSaveLanguageBtn }
                stopPropagation={ stopPropagation }
              />
          }
        </div>
      </div>
  )
}
