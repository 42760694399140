import React, { type FC, useState, useEffect, useMemo, type ChangeEvent, type FocusEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
  AssetAmountInputComponent,
  ButtonComponent,
  CreditCardInputComponent,
  CheckboxComponent
} from '../../../../components';
import { createExternalTransaction, getTransactionsHistory } from '../../../../redux/reducers/transactionsHistory';
import { getCards, addCard } from '../../../../redux/reducers/withdrawalMethods';
import { getCurrencyRates } from '../../../../redux/reducers/currencyRates';
import { SavedCardWithdrawalOptions } from './SavedCardWithdrawalOptions/SavedCardWithdrawalOptions';
import { SuccessWithdrawalSection } from '../SuccessWithdrawalSection/SuccessWithdrawalSection';
import style from './CardWithdrawalSection.module.css';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { toFixed } from '../../../../helpers/util';
import { isCardValid } from '../../helper';
import { t } from 'i18next';
import { useWithdrawalDispatchTransaction } from '../../../../hooks/useWithdrawalDispatchTransaction';

export interface TypeCardOption { currency: string, value: string }

interface InterfaceCardWithdrawSection {
  setOpen: (el: boolean) => void
  setStep: (el: number) => void
  step: number
  selectedAccount: IAccount | null
}

interface ICreateWithdrawalData {
  userId: string
  currencyId: string
  userAccountId: string
  sendToAddress: string
  amount: number | string
  amountFromAccount: number | string
  type: 'Card'
}

interface IAddCardData {
  name: string
  card: string
  currencyId: string
  userId: string
}

export const CardWithdrawSection: FC<InterfaceCardWithdrawSection> = ({ setOpen, setStep, step, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const [cardSelect, setCardSelect] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [isAddCard, setIsAddCard] = useState<boolean>(false);
  const [cardNumber, setCardNumber] = useState<string>('');
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const { _id: userId, firstName, lastName } = useAppSelector((state) => state.user);
  const { cards } = useAppSelector((state) => state.withdrawalMethods);
  const { rates: currencyRates } = useAppSelector((state) => state.currencyRates);
  const { executeTransaction } = useWithdrawalDispatchTransaction(setIsBtnLoading);

  const validCardNumber = isCardValid(cardNumber);
  const formComplete = (amount.length > 0 && Number(amount) > 0 && cardSelect.length > 0) || (validCardNumber && amount.length > 0 && Number(amount) > 0);

  useEffect(() => {
    if (userId === undefined) return;

    dispatch(getCards(userId));
  }, [userId]);

  useEffect(() => {
    if (cards.length > 0 && cards !== undefined) {
      const [{ _id }] = cards;
      setCardSelect(_id);
    }
  }, [cards])

  useEffect(() => {
    if (selectedAccount !== null) dispatch(getCurrencyRates(selectedAccount.cfdAccountCurrency.symbol));
  }, [selectedAccount]);

  const selectedCard = useMemo(() => {
    return cards.find(({ _id }) => _id === cardSelect) ?? null;
  }, [cardSelect])

  const handleWithdrawlClick = async (): Promise<void> => {
    const chosenCard = selectedCard?.card ?? cardNumber;
    const validCard = isCardValid(chosenCard);
    if (userId === undefined || !validCard || selectedAccount === null) return;

    setIsBtnLoading(true);

    if (isAddCard) {
      const addCardData: IAddCardData = {
        name: `${firstName} ${lastName}`,
        card: cardNumber,
        currencyId: selectedAccount.cfdAccountCurrency.currencyId,
        userId
      };

      const addCardSuccess = await executeTransaction(addCard, addCardData);
      if (!addCardSuccess) return;
    }

    const createWithdrawalData = (): ICreateWithdrawalData => {
      const currencySymbol = selectedAccount.cfdAccountCurrency?.symbol ?? '';
      const currencyRate = Number(currencyRates?.[currencySymbol] ?? 0);
      const calculatedAmount = toFixed(Number(amount) / currencyRate, 2);

      return {
        userId,
        currencyId: selectedAccount.cfdAccountCurrency.currencyId,
        userAccountId: selectedAccount._id,
        sendToAddress: chosenCard,
        amount: calculatedAmount,
        amountFromAccount: amount,
        type: 'Card'
      };
    };

    const withdrawalData = createWithdrawalData();
    const transactionSuccess = await executeTransaction(createExternalTransaction, withdrawalData);
    if (!transactionSuccess) return;

    dispatch(getTransactionsHistory({ userId, accountId: selectedAccount._id }));
    setStep(2);
  };

  const handleCloseWithdrawal = (): void => {
    setOpen(false);
  }

  const checkBoxHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCheckboxValue(!checkboxValue);
    setIsAddCard(!isAddCard);
  }

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    setCardSelect('')
  };

  const savedCardHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setCardSelect(event.target.value);
    setCardNumber('');
    setCheckboxValue(false);
  }

  const selectedCardView = (isAddCard && isCardValid(cardNumber)) ? String(cardNumber) : String(selectedCard?.card ?? cardNumber);

  return (
    <>
      { (step === 1)
        ? <>
            <div className={ style.wrapper }>
              <div className={ style.cardWrapper }>
                  <div className={ style.cardWrapperInput }>
                    <p className={ style.cardWrapperText }>{t('labels.amount_to_withdrawal')}</p>
                    <div className={ style.inputWrapper }>
                      <AssetAmountInputComponent
                        value={ amount }
                        setValue={ setAmount }
                        id='amount'
                        currency={selectedAccount?.cfdAccountCurrency.symbol ?? ''}
                        maxBtn={ false }
                        placeholder='0.00'
                        showCurrency={false}
                      />
                    </div>
                  </div>
                { (cards.length !== 0) &&
                    <div className={ style.savedCardsWrapper }>
                      <p className={ style.cardWrapperText }>{t('labels.choose_card')}</p>
                      <SavedCardWithdrawalOptions selectedOptions={ cardSelect } setSelectedOptions={ setCardSelect } options={ cards } onChange={ savedCardHandler }/>
                    </div>
                }
              </div>
              <div className={ style.cardWrapper }>
                <p className={ style.cardWrapperText }>{t('deposit_modal.card_form.card_label')}</p>
                <div className={ style.inputWrapper }>
                  <CreditCardInputComponent
                    value={ cardNumber }
                    setValue={ setCardNumber }
                    id='creditCard'
                    onFocus={ handleFocus }
                    placeholder={ `${t('labels.enter_card_number')}` }
                  />
                </div>
                <div className={style.checkboxContainer}>
                  <CheckboxComponent disabled={!validCardNumber} checked={checkboxValue} onChange={checkBoxHandler}/>
                  <p className={style.checkboxTitle}>{t('withdrawal_modal.save_card_for_future')}</p>
                </div>
              </div>
            </div>
            <footer className={ style.footer }>
              <div className={ style.btnContainer }>
                <ButtonComponent onClick={() => { handleWithdrawlClick(); } } disabled={ !formComplete } loading={isBtnLoading}>
                  <span className={ style.btnText }>
                    {t('withdrawal_modal.add_bank_form_modal.footer.action_button_text')}
                  </span>
                </ButtonComponent>
              </div>
            </footer>
          </>
        : <SuccessWithdrawalSection
            title={t('withdrawal_modal.success_title')}
            amount={`${toFixed(Number(amount), 2)}`}
            currency={selectedAccount?.cfdAccountCurrency?.symbol ?? ''}
            withdrawalTo={selectedCardView}
            onClick={ handleCloseWithdrawal }
            sectionName='Card'
          />
      }
    </>
  )
}
