import React, { type FC, type Dispatch, type SetStateAction, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getNews } from '../../../../redux/reducers/news';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NewItem } from './components/NewItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TradeElementHeight } from '../../helper';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { t } from 'i18next';

const CustomAccordion = styled(Accordion)(() => ({
  backgroundColor: 'var(--CFD-theme-Surface-2)',
  border: '1px solid var(--CFD-theme-State-layers-outline-opacity_16)',
  borderRadius: '6px',
  height: '63px',
  boxShadow: 'none',
  '&.Mui-expanded': {
    borderBottom: 'unset',
    marginTop: '0',
    borderRadius: '6px 6px 0 0'
  },
  ' .MuiButtonBase-root': {
    padding: '8px 8px 8px 16px',
    height: '100%',
    color: 'var(--CFD-theme-System-OnSurface)',
    fontFamily: 'Arial',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '32px'
  },
  ' .MuiCollapse-root': {
    margin: '-1px'
  }

  // ' .MuiCollapse-wrapperInner': {
  //   maxHeight: '248px'
  // }
}));

const CustomAccordionDetails = styled(AccordionDetails)(() => ({
  backgroundColor: 'var(--CFD-theme-Surface-1)',
  border: '1px solid var(--CFD-theme-State-layers-outline-opacity_16)',
  padding: '16px',
  overflowY: 'auto',
  borderRadius: '0 0 6px 6px',

  ' .newItemWrapper': {
    marginBottom: '16px',

    '&:last-of-type': {
      marginBottom: '0'
    }
  }
}));

interface INews {
  calcHeight: number
  isMarketShown: boolean
  isNewsShown: boolean
  isNewsOpened: boolean
  setIsNewsOpened: (prevState: SetStateAction<boolean>) => void
  setMaxMarketHeight?: Dispatch<SetStateAction<number>>
  setIsNewsMobileShow?: (prevState: SetStateAction<boolean>) => void
}

export const News: FC<INews> = ({ setMaxMarketHeight, isNewsShown, isMarketShown, setIsNewsMobileShow, calcHeight, isNewsOpened, setIsNewsOpened }) => {
  const dispatch = useAppDispatch();
  const news = useAppSelector((state) => state.news);
  const [screenWidth] = useWindowSize();

  const isMobile = screenWidth <= 1280;

  const maxNewsHeight = calcHeight - TradeElementHeight.NewsAccordionHeader - TradeElementHeight.PaddingTop - TradeElementHeight.NewsAccordionPadding;

  useEffect(() => {
    dispatch(getNews());
  }, []);

  useEffect(() => {
    setIsNewsOpened(!isMarketShown);
  }, [isMarketShown]);

  const handleAccordionChange = (value: boolean): void => {
    const maxMarketHeight = calcHeight - TradeElementHeight.PaddingTop;
    const minMarketHeight = maxMarketHeight - TradeElementHeight.NewsAccordionBody - TradeElementHeight.NewsAccordionHeader - TradeElementHeight.Gap;

    const height = value ? minMarketHeight : maxMarketHeight;
    if (setMaxMarketHeight != null) setMaxMarketHeight(height);
    if (setIsNewsMobileShow != null) setIsNewsMobileShow((prevState) => !prevState);
    setIsNewsOpened(value)
  }

  const dynamicAccordionDetailsStyle = useMemo(() => {
    const isSmallHeight = calcHeight < 522;
    const minimumTradeHeight = 250;
    const calcSmallTableHeight = calcHeight - minimumTradeHeight - TradeElementHeight.PaddingTop - TradeElementHeight.GapBetweenTradeAndTable - TradeElementHeight.OrderAccordionHeader;

    const resultCalcSmallTableHeight = calcSmallTableHeight < 75 ? 75 : calcSmallTableHeight;

    return isSmallHeight ? resultCalcSmallTableHeight : 128;
  }, [calcHeight]);

  return (
    <>
    {
      isNewsShown && (
        <CustomAccordion onChange={(e, value) => { handleAccordionChange(value); }} expanded={isNewsOpened}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }} />}
          aria-controls="news-content"
          id="news-header"
        >
          {t('trading_page.labels.news')}
        </AccordionSummary>
        <CustomAccordionDetails sx={{
          maxHeight: `${isMarketShown ? (isMobile ? '230px' : `${dynamicAccordionDetailsStyle}px`) : `${maxNewsHeight}px`}`,
          minHeight: `${isMarketShown ? (isMobile ? '230px' : `${dynamicAccordionDetailsStyle}px`) : `${maxNewsHeight}px`}`
        }}>
        {
          news.map((cfdNew) => (
            <NewItem key={cfdNew._id} cfdNew={cfdNew}/>
          ))
        }
        </CustomAccordionDetails>
    </CustomAccordion>
      )
    }
    </>
  )
}
