import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';

export interface IAlertMessageResponse {
  _id: string
  actions: string
  type: string
  value: string
  brandId: string[]
  brandName: string[]
  createdAt: string | Date
  updatedAt: string | Date
  variant: 'default' | 'warning' | 'error'
  managerNames: string[]
}

export interface IRunningAdvertisementResponse {
  _id: string
  actions: string
  type: string
  value: boolean
  brandId: string[]
  brandName: string[]
  createdAt: string | Date
  updatedAt: string | Date
  variant: null
  managerNames: string[]
}

interface InitState {
  isAlertMessage: boolean
  isRunningAdvertisement: boolean
  runningAdvertisementData: IRunningAdvertisementResponse[]
  alertMessageData: IAlertMessageResponse[]
}

const initialState: InitState = {
  isAlertMessage: false,
  alertMessageData: [],
  isRunningAdvertisement: false,
  runningAdvertisementData: []
};

export const getAlertMessage = createAsyncThunk(
  'alertMessage/get',
  async () => {
    const url = '/variables/alert-message';

    const response = await apiHelper({ method: 'get', path: `${url}` });

    return response.data.variables;
  }
);

export const getRunningAdvertisement = createAsyncThunk(
  'runningAdvertisement/get',
  async () => {
    const url = '/variables/running-advertisement';

    const response = await apiHelper({ method: 'get', path: `${url}` });

    return response.data.runningAdvertisement;
  }
);

export const alertMessageSlice = createSlice({
  name: 'alertMessage',
  initialState,
  reducers: {
    setShowAlertMessage: (state, action: PayloadAction<boolean>) => {
      state.isAlertMessage = action.payload;
    },
    setShowRunningAdvertisement: (state, action: PayloadAction<boolean>) => {
      state.isRunningAdvertisement = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAlertMessage.fulfilled, (state, action: PayloadAction<IAlertMessageResponse[]>) => {
      state.alertMessageData = action.payload;
    });
    builder.addCase(getRunningAdvertisement.fulfilled, (state, action: PayloadAction<IRunningAdvertisementResponse[]>) => {
      state.runningAdvertisementData = action.payload;
    });
  }
});

export const { setShowAlertMessage, setShowRunningAdvertisement } = alertMessageSlice.actions;

export default alertMessageSlice.reducer;
