import React, { type FC, type MouseEvent } from 'react';
import { t } from 'i18next';

import chatIcon from '../../../assets/images/chatIcon.svg'
import { LiveChatComponent } from '../LiveChatComponent';

import styles from './FooterChatComponent.module.css';

interface IFooterChatComponent {
  onClickHandler: (e: MouseEvent<HTMLButtonElement>) => void
  open: boolean
  unreadMessageCount: string
  setOpen: (el: boolean) => void
  setOpenDeposit: (el: boolean) => void
}

export const FooterChatComponent: FC<IFooterChatComponent> = ({ onClickHandler, open, unreadMessageCount, setOpen, setOpenDeposit }) => {
  return (
    <>
    <button
      className={
        `${open ? `${styles.footerComponentWrapper} ${styles.footerComponentWrapperSelect}` : `${styles.footerComponentWrapper}`}`
      }
      onClick={onClickHandler}
    >
      <div className={styles.footerComponentIconWrapper}>
        <div className={styles.footerComponentIcon}>
          <svg className={styles.svgSize}>
            <use xlinkHref={`${chatIcon}#chatIcnoLogo`}/>
          </svg>
          <div className={styles.footerIconBadge}>
            { unreadMessageCount }
          </div>
        </div>
      </div>
      <div>
        <p className={styles.footerComponentText}>{t('footer.labels.live_chat')}</p>
      </div>
    </button>
    { open && <LiveChatComponent setOpen={ setOpen } open={ open } setOpenDeposit={ setOpenDeposit }/>}
    </>
  );
};
