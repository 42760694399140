import React, { useState, type FC, useEffect, useMemo } from 'react';
import { t } from 'i18next';

import { useAppSelector } from '../../../../hooks/redux';
import { toFixed, getCurrencySymbol, calculatePercentage } from '../../../../helpers/util';

import styles from './MobileTradeFooter.module.css';

interface IViewInfoItems {
  label: string
  value: number | string
  style?: Record<string, string>
}

export const MobileTradeFooter: FC = () => {
  const [pnl, setPnl] = useState<number>(0);
  const [margin, setMargin] = useState<number>(0);
  const { headerRates } = useAppSelector((state) => state.currencyRates);
  const accounts = useAppSelector((state) => state.accounts);
  const { accountIdToPnl, accountIdToMargin } = useAppSelector((state) => state.socketData);

  const selectedAccount = useMemo(() => (
    accounts.find((account) => account.isActive) ?? null
  ), [accounts]);

  const symbolIcon = useMemo(() => (getCurrencySymbol(selectedAccount?.cfdAccountCurrency.symbol ?? '')), [selectedAccount])

  const equity = (selectedAccount?.balance ?? 0) + pnl + (selectedAccount?.credit ?? 0);
  const freeMargin = equity - margin;
  const marginLevel: number = calculatePercentage(Number(equity), Number(margin));

  useEffect(() => {
    if (selectedAccount !== null) {
      const accountId = selectedAccount._id;

      setMargin(accountIdToMargin[accountId] ?? 0);
    }
  }, [accountIdToMargin, selectedAccount]);

  useEffect(() => {
    if (selectedAccount !== null) {
      const accountId = selectedAccount._id;
      const symbol = selectedAccount.cfdAccountCurrency.symbol

      const convertedPnl = Number(accountIdToPnl[accountId] ?? 0) * (headerRates?.[symbol] ?? 1);

      setPnl(convertedPnl);
    }
  }, [accountIdToPnl, selectedAccount])

  const viewInfoItems: IViewInfoItems[] = [
    { label: t('labels.balance'), value: `${symbolIcon} ${toFixed(selectedAccount?.balance ?? 0, 2)}` },
    { label: t('header.labels.credit'), value: `${symbolIcon} ${toFixed(selectedAccount?.credit ?? 0, 2)}` },
    { label: t('header.labels.equity'), value: `${symbolIcon} ${toFixed(equity, 2)}` },
    { label: t('labels.margin'), value: `${symbolIcon} ${toFixed(margin, 2)}` },
    { label: t('labels.margin_level'), value: `${toFixed(marginLevel, 2)}%` },
    { label: t('labels.free_margin'), value: `${symbolIcon} ${toFixed(freeMargin, 2)}` },
    {
      label: t('header.labels.pnl'),
      value: `${symbolIcon} ${toFixed(pnl, 2)}`,
      style: { color: pnl >= 0 ? 'var(--CFD-theme-Custom-Green)' : 'var(--CFD-theme-Custom-Red)' }
    }
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
      {
        viewInfoItems.map((item, index) => (
          <div key={index} className={styles.infoCell}>
            <p className={styles.title}>{item.label}</p>
            <p className={styles.amount} style={item.style}>{item.value}</p>
          </div>
        ))
      }
      </div>
    </div>
  )
}
