import React, { type FC } from 'react';
import { useTheme } from '../../components';
import darkLogo from '../../assets/images/logos/darkLogo.svg';
import lightLogo from '../../assets/images/logos/lightLogo.svg';

export const LogoIcon: FC = () => {
  const { theme } = useTheme();

  return (
    <>
      {theme === 'dark'
        ? (
        <svg width="42" height="36">
          <use xlinkHref={`${darkLogo}#dark-logo`} />
        </svg>
          )
        : (
        <svg width="42" height="36">
          <use xlinkHref={`${lightLogo}#light-logo`} />
        </svg>
          )}
    </>
  );
};
