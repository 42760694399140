import React, { type FC, type MouseEvent, useState } from 'react';
import { RadioGroup, FormControl, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { type IAccount } from '../../../redux/reducers/accounts';
import { CellHeaderAccountSelect } from '../CellHeaderAccountSelect/CellHeaderAccountSelect';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setShowFooter } from '../../../redux/reducers/showFooter';

import styles from './FormControllerHeaderAccountSelect.module.css';

interface IFormControllerHeaderAccountSelect {
  selectedAccount: IAccount | null
  setOpenDeposit: (el: boolean) => void
  setOpenWithdrawal: (el: boolean) => void
  setOpenSelectAccounts: (el: boolean) => void
  setChosenSelectedAccount: (el: IAccount | null) => void
}

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%'
}))

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'flex-start',
  gap: '16px'
}))

export const FormControllerHeaderAccountSelect: FC<IFormControllerHeaderAccountSelect> = ({
  selectedAccount,
  setOpenDeposit,
  setOpenWithdrawal,
  setOpenSelectAccounts,
  setChosenSelectedAccount
}) => {
  const dispatch = useAppDispatch();
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>(selectedAccount?._id ?? '');
  const navigate = useNavigate();
  const accounts = useAppSelector((state) => state.accounts);

  const depositBtnHandler = (event: MouseEvent<HTMLButtonElement>, account: IAccount): void => {
    event.stopPropagation();
    setChosenSelectedAccount(account);
    setOpenDeposit(true);
    setOpenSelectAccounts(false);
  }

  const withdrawalBtnHandler = (event: MouseEvent<HTMLButtonElement>, account: IAccount): void => {
    event.stopPropagation();
    setChosenSelectedAccount(account);
    setOpenWithdrawal(true);
    setOpenSelectAccounts(false);
  }

  const linkBtnHandler = (event: MouseEvent<HTMLButtonElement>, accountId: string): void => {
    event.stopPropagation();
    navigate(accountId.length > 0 ? `/accounts/${accountId}` : '/accounts');
    setOpenSelectAccounts(false);
    dispatch(setShowFooter(true))
  }
  return (
    <div className={ styles.contentWrapper }>
      <StyledFormControl>
        <StyledRadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {accounts.map((el) => (
            <CellHeaderAccountSelect
              account={ el }
              key={ el._id }
              depositBtnHandler={ depositBtnHandler }
              withdrawalBtnHandler={ withdrawalBtnHandler }
              linkBtnHandler={ linkBtnHandler }
              setSelectedRadioValue={ setSelectedRadioValue }
              selectedRadioValue={ selectedRadioValue }
            />
          ))}
        </StyledRadioGroup>
      </StyledFormControl>
    </div>
  )
}
