import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface ISocketData {
  accountIdToPnl: Record<string, number>
  accountIdToMargin: Record<string, number>
}

const initialState: ISocketData = {
  accountIdToPnl: {},
  accountIdToMargin: {}
};

export const socketDataSlice = createSlice({
  name: 'socketData',
  initialState,
  reducers: {
    setAccountIdToPnl (state, action: PayloadAction<Record<string, number>>) {
      state.accountIdToPnl = action.payload
    },
    setAccountIdToMargin (state, action: PayloadAction<Record<string, number>>) {
      state.accountIdToMargin = action.payload;
    }
  }
});

export const { setAccountIdToPnl, setAccountIdToMargin } = socketDataSlice.actions
export default socketDataSlice.reducer;
