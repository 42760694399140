import { handleError } from '../helpers';
import { type PayloadInterface } from '../interfaces';
import { useAppDispatch } from '../hooks/redux';
import { useSnackbar } from '../components';

type ExecuteTransactionFunction = <T>(
  action: (data: T) => any,
  data: T
) => Promise<boolean>;

export const useWithdrawalDispatchTransaction = (
  setIsBtnLoading: (loading: boolean) => void
): { executeTransaction: ExecuteTransactionFunction } => {
  const dispatch = useAppDispatch();
  const { handleOpen: handleOpenSnackbar } = useSnackbar();

  const executeTransaction: ExecuteTransactionFunction = async (action, data) => {
    setIsBtnLoading(true);
    try {
      const response = await dispatch(action(data));
      const payload = response.payload as PayloadInterface;

      if (payload.isError) {
        handleError(payload, handleOpenSnackbar);
        setIsBtnLoading(false);
        return false;
      }

      setIsBtnLoading(false);
      return true;
    } catch (error) {
      handleError(error as PayloadInterface, handleOpenSnackbar);
      setIsBtnLoading(false);
      return false;
    }
  };

  return { executeTransaction };
};
