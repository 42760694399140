import React, { type FC } from 'react';

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { BinIcon } from '../../../../components/icons/BinIcon';
import { SwitchComponent, useSnackbar } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { switchOtpStatus2FA } from '../../../../redux/reducers/twoFactorAuthentication';
import style from './TwoFAContent.module.css';
import { profileSettingsSelector } from '../../../../redux/selectors/profileSettings';

interface InterfaceTwoFaContent {
  handleEditIcon: () => void
  handleBinIcon: () => void
}

export const TwoFAContent: FC<InterfaceTwoFaContent> = ({ handleBinIcon, handleEditIcon }) => {
  const dispatch = useAppDispatch()
  const { handleOpen } = useSnackbar()

  const {
    twoFactorAuthDateAdded,
    isGoogleTwoFactorAuthenticationEnabled
  } = useAppSelector(profileSettingsSelector);
  const switch2FA = (): any => dispatch(switchOtpStatus2FA(handleOpen))

  if (twoFactorAuthDateAdded === '') return <></>;

  return (
    <div className={ style.settings2FAVariantWrapper }>
        <div className={ style.settings2FAVariantContainer }>
            <div className={ style.settings2FATitleContainer }>
                <h5 className={ style.settings2FABtnTitle }>Google Two Factor Authentication</h5>
                <div className={ style.settings2FAIconContainer }>
                    <CreateOutlinedIcon
                      sx={{ color: 'var(--CFD-theme-System-OnSurface)', cursor: 'pointer' }}
                      onClick={handleEditIcon}
                    />
                    <BinIcon onClick={handleBinIcon} />
                </div>
            </div>
            <div className={ style.settings2FABtn }>
                <SwitchComponent checked={isGoogleTwoFactorAuthenticationEnabled} onChange={switch2FA} />
                <p className={ style.settings2FABtnParagraph }>Added: { twoFactorAuthDateAdded }</p>
            </div>
        </div>
    </div>
  )
}
