import React, { type MouseEvent, type FC, useEffect } from 'react';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { ButtonComponent, DividerComponent } from '../../../components';
import { type IAccount } from '../../../redux/reducers/accounts';
import { setShowFooter } from '../../../redux/reducers/showFooter';
import { FormControllerHeaderAccountSelect } from '../FormControllerHeaderAccountSelect/FormControllerHeaderAccountSelect';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { MobileSelectAccountHeader } from './MobileSelectAccountHeader/MobileSelectAccountHeader';
import { useBodyOverflow } from '../../../hooks/useBodyOverflow';
import { selectIsAlertMessage, selectIsRunningAdvertisement } from '../../../redux/selectors/alertMessage';

import styles from './SelectAccountHeader.module.css';

interface ISelectAccountHeader {
  setOpenSelectAccounts: (el: boolean) => void
  setOpenDeposit: (el: boolean) => void
  setChosenSelectedAccount: (el: IAccount | null) => void
  setOpenWithdrawal: (el: boolean) => void
  selectedAccount: IAccount | null
}

export const SelectAccountHeader: FC<ISelectAccountHeader> = ({ setOpenSelectAccounts, setOpenDeposit, setChosenSelectedAccount, setOpenWithdrawal, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const [screenWidth] = useWindowSize();
  const location = useLocation();
  const isAlertMessage = useAppSelector(selectIsAlertMessage);
  const isRunningAdvertisement = useAppSelector(selectIsRunningAdvertisement);
  const isTradingPage = location.pathname.includes('/trade')
  const isRunningAdvertisementShown = (isRunningAdvertisement && isTradingPage)
  const isAlertMessageShown = (isAlertMessage && isTradingPage);

  const isMobile = screenWidth <= 600;
  useBodyOverflow(isMobile);

  const stopPropagation = (event: MouseEvent): void => {
    event.stopPropagation();
  };

  useEffect(() => {
    const isMobileSize = screenWidth <= 600;
    if (isMobileSize) dispatch(setShowFooter(false));
    else dispatch(setShowFooter(true));
  }, [screenWidth]);

  const exitHandler = (): void => {
    setOpenSelectAccounts(false);
  }

  return (
    <div className={ styles.background } onClick={ () => { setOpenSelectAccounts(false); } }>
      <div className={ styles.container }>
        {
          !isMobile
            ? (
              <div
                className={ styles.wrapper }
                style={{ position: (isAlertMessageShown || isRunningAdvertisementShown) ? 'absolute' : 'fixed' }}
                onClick={ stopPropagation }
              >
                <div className={ styles.titleWrapper } >
                  <p className={ styles.title }>{t('labels.selected_account')}</p>
                  <button className={ styles.crossContainer } onClick={ exitHandler }>
                    <CloseIcon sx={{ width: '24px', height: '24px', color: 'var(--CFD-theme-System-OnSurface)' }} />
                  </button>
                  </div>
                  <DividerComponent />
                  <FormControllerHeaderAccountSelect
                    selectedAccount={ selectedAccount }
                    setOpenDeposit={ setOpenDeposit }
                    setOpenSelectAccounts={ setOpenSelectAccounts }
                    setChosenSelectedAccount={ setChosenSelectedAccount }
                    setOpenWithdrawal={ setOpenWithdrawal }
                  />
                  <div className={ styles.footer }>
                  <div className={ styles.btnFooterContainer }>
                    <ButtonComponent variant='outlined' onClick={ exitHandler }>{t('labels.save')}</ButtonComponent>
                  </div>
                </div>
              </div>
              )
            : (
                <MobileSelectAccountHeader
                  setOpenSelectAccounts={ setOpenSelectAccounts }
                  setOpenDeposit={ setOpenDeposit }
                  setOpenWithdrawal={ setOpenWithdrawal }
                  setChosenSelectedAccount={ setChosenSelectedAccount }
                  selectedAccount={ selectedAccount }
                />
              )
        }
      </div>
    </div>
  );
}
