import React, { type FC, type Dispatch, type SetStateAction, useState, useEffect } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Accordion, AccordionSummary, AccordionDetails, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { t } from 'i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setShowFooter } from '../../../redux/reducers/showFooter';
import { Market, News, Chart, Table, BuySell } from '../components';
import { ButtonComponent } from '../../../components';
import { BuySellModalMobile } from './BuySellModalMobile/BuySellModalMobile';
import { setTradingMode } from '../../../redux/reducers/tradingMode';
import { getCurrencyRates } from '../../../redux/reducers/currencyRates';
import { getAccounts } from '../../../redux/reducers/cfd';
import { TradeElementHeight } from '../helper';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ModalAutoTrader } from '../../../components/ModalAutoTrader/ModalAutoTrader';
import { MobileTradeFooter } from './MobileTradeFooter/MobileTradeFooter';
import { selectIsAlertMessage, selectIsRunningAdvertisement } from '../../../redux/selectors/alertMessage';

import style from './MobileViewTrade.module.css';

const CustomAccordion = styled(Accordion)(() => ({
  backgroundColor: 'var(--CFD-theme-System-Surface)',
  border: '1px solid var(--CFD-theme-State-layers-outline-opacity_16)',
  width: '100%',
  height: '63px',
  boxShadow: 'none',
  '&.Mui-expanded': {
    borderBottom: 'unset',
    marginTop: '0',
    borderRadius: '6px 6px 0 0'
  },
  ' .MuiButtonBase-root': {
    padding: '4px 4px 4px 4px',
    height: '100%',
    color: 'var(--CFD-theme-System-OnSurface)'
  },
  ' .MuiCollapse-root': {
    margin: '-1px'
  }
}));

const CustomAccordionDetails = styled(AccordionDetails)(() => ({
  backgroundColor: 'var(--CFD-theme-System-Surface)',
  padding: '16px',
  overflowY: 'auto',
  minHeight: '80vh',
  borderRadius: '0 0 6px 6px',
  ' .MuiButtonBase-root': {
    height: '100vh'
  },
  ' .newItemWrapper': {
    marginBottom: '16px',

    '&:last-of-type': {
      marginBottom: '0'
    }
  }
}));

interface InterfaceMobileViewTrade {
  uniqueId: number
  isMarketShown: boolean
  isNewsShown: boolean
  isOpened: boolean
  setIsMarketShown: Dispatch<SetStateAction<boolean>>
  setIsNewsShown: Dispatch<SetStateAction<boolean>>
  setMaxTradingHeight: Dispatch<SetStateAction<number>>
  calcHeight: number
}

export const MobileViewTrade: FC<InterfaceMobileViewTrade> = ({
  uniqueId,
  isMarketShown,
  isNewsShown,
  isOpened,
  setIsMarketShown,
  setIsNewsShown,
  setMaxTradingHeight,
  calcHeight
}) => {
  const dispatch = useAppDispatch();
  const [isNewsMobileShow, setIsNewsMobileShow] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false);
  const [modalBuySell, setModalBuySell] = useState<boolean>(false);
  const [screenWidth] = useWindowSize();
  const [paddingBetweenNewsMarket, setPaddingBetweenNewsMarket] = useState<'0px' | '234px'>('0px');
  const [openModalAutoTraider, setModalAutoTraider] = useState<boolean>(false);
  const [isNewsOpened, setIsNewsOpened] = useState<boolean>(false);
  const { tradingMode } = useAppSelector((state) => state.tradingMode);
  const isAlertMessage = useAppSelector(selectIsAlertMessage);
  const isRunningAdvertisement = useAppSelector(selectIsRunningAdvertisement);
  const { _id: userId } = useAppSelector((state) => state.user);

  const isMobile = screenWidth <= 600;
  const isTable = (screenWidth <= 1280 && screenWidth > 600);
  const footerInfoHeight = isMobile ? TradeElementHeight.MobileTradeFooter : TradeElementHeight.TableTradeFooter;

  useEffect(() => {
    dispatch(getCurrencyRates('USD'))
  }, []);

  useEffect(() => {
    if (userId !== undefined && userId.length > 0) {
      dispatch(getAccounts(userId));
    }
  }, [userId]);

  const btnTradingModeHandle = (): void => {
    dispatch(setTradingMode(false));
  }

  const btnBuySellHandler = (): void => {
    setModalBuySell(true);
  }

  const handleExpansion = (): void => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (tradingMode) dispatch(setShowFooter(false));
    else dispatch(setShowFooter(true));
  }, [tradingMode])

  useEffect(() => {
    setPaddingBetweenNewsMarket(isNewsMobileShow ? '234px' : '0px');
  }, [isNewsMobileShow]);

  const handleModalAutoTraiderOpen = (): void => {
    setModalAutoTraider(true);
  }

  const calculatedWrapperHeight = !isTable && (isAlertMessage || isRunningAdvertisement)
    ? calcHeight
    : ((isTable && isRunningAdvertisement)
        ? (calcHeight + TradeElementHeight.NewsAccordionHeader - TradeElementHeight.RunningAdvertisement - TradeElementHeight.Gap)
        : calcHeight);

  return (
    <>
    { !tradingMode
      ? <div className={ style.tradeMobileWrapper }
          style={{ height: `${calculatedWrapperHeight}px` }}
        >
        <News
          isMarketShown={ isMarketShown }
          isNewsShown={ isNewsShown }
          setIsNewsMobileShow={ setIsNewsMobileShow }
          calcHeight={ calcHeight }
          isNewsOpened={ isNewsOpened }
          setIsNewsOpened={ setIsNewsOpened }
        />
        <div
          style={{
            paddingTop: paddingBetweenNewsMarket,
            paddingBottom: isMobile ? `${TradeElementHeight.MobileFooter}px` : `${TradeElementHeight.Footer}px`,
            height: `calc(100% - ${TradeElementHeight.NewsAccordionHeader} - ${TradeElementHeight.MarketMargin} - ${TradeElementHeight.MobileFooter})px`
          }}
        >
          <Market uniqueId={ uniqueId } isMarketShown={ isMarketShown } setIsNewsMobileShow={ setIsNewsMobileShow }/>
        </div>
      </div>
      : <>
          <div className={ style.tradeModeWrapper }>
            <div className={ style.tradeModeWrapperHeader }>
              <h1 className={ style.tradeModeWrapperHeaderTitle }>{t('trading_page.labels.trading')}</h1>
              <div className={style.tradeModeWrapperHeaderButtons}>
                <div className={style.buttonAutoTrader}>
                  <ButtonComponent onClick={handleModalAutoTraiderOpen} variant='outlined'>
                    {t('trading_page.labels.autoTrader')}
                  </ButtonComponent>
                </div>
                <button className={ style.crossBtn } onClick={ btnTradingModeHandle }>
                  <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                </button>
              </div>
            </div>
            <div className={ style.tradeModeMarketWrapper }>
              <CustomAccordion
                expanded={ expanded }
                onChange={ handleExpansion }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)', padding: '0 16px' }}/>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ borderRadius: '0' }}
                >
                  <span className={ style.marketTitle }>{t('trading_page.labels.market')}</span>
                </AccordionSummary>
                <CustomAccordionDetails>
                  <div style={{ height: '80vh' }}>
                    <Market uniqueId={ uniqueId } isMarketShown={ isMarketShown } hideHeader setExpanded={ setExpanded } />
                  </div>
                </CustomAccordionDetails>
              </CustomAccordion>
            </div>
            <div
              className={ style.tradeModeChartWrapper }
            >
              <Chart
                isOpened={ isOpened }
                uniqueId={ uniqueId }
                isNewsShown={ isNewsShown }
                isMarketShown={ isMarketShown }
                setIsMarketShown={ setIsMarketShown }
                setIsNewsShown={ setIsNewsShown }
                calcHeight={ calcHeight }
              />
            </div>
              <div
                className={ style.tradeModeTable }
                style={{
                  display: expanded ? 'none' : 'flex',
                  bottom: isTableExpanded ? `${TradeElementHeight.MobileOrderAccordionBody + footerInfoHeight}px` : '80px'
                }}
              >
                <Table
                  uniqueId={ uniqueId }
                  setMaxTradingHeight={ setMaxTradingHeight }
                  isTableExpanded={ isTableExpanded }
                  setIsTableExpanded={ setIsTableExpanded }
                  calcHeight={ calcHeight }
                />
              </div>
              <div
                className={ style.tradeModeBtnContainer }
                style={{
                  display: expanded ? 'none' : 'flex',
                  bottom: `${footerInfoHeight}px`
                }}
              >
                <ButtonComponent btnstyle='buy' onClick={ btnBuySellHandler }>{t('trading_page.labels.buy')}</ButtonComponent>
                <ButtonComponent btnstyle='sell' onClick={ btnBuySellHandler }>{t('trading_page.labels.sell')}</ButtonComponent>
              </div>
              {!expanded && <MobileTradeFooter />}
          </div>
          <BuySellModalMobile open={ modalBuySell } setOpen={ setModalBuySell }>
            <BuySell isOpened={ isOpened } uniqueId={ uniqueId } setModalBuySell={ setModalBuySell } />
          </BuySellModalMobile>
        </>
      }
      {
        openModalAutoTraider && <ModalAutoTrader open={openModalAutoTraider} setOpen={setModalAutoTraider}/>
      }
    </>
  )
}
