import React from 'react';
import { AuthLayout } from './AuthLayout';
import { PrivateLayout } from './PrivateLayout';
import { PublicLayout } from './PublicLayout';
import { Login } from '../pages/Auth/Login/Login';
import { Registration } from '../pages/Auth/Registration/Registration';
import { PasswordRecovery } from '../pages/Auth/PasswordRecovery/PasswordRecovery';
import { ForgotPassword } from '../pages/Auth/ForgotPassword/ForgotPassword';
import { VerifyAccount } from '../pages/Auth/VerifyAccount/VerifyAccount';
import { Trade } from '../pages/Trade/Trade';
import { Profile } from '../pages/Profile/Profile';
import { Savings } from '../pages/Savings/Savings';
import { AccountsPage } from '../pages/Accounts/Accounts';
import { PageNotFound } from '../pages/PageNotFound/PageNotFound';

export const navigation = [
  {
    path: '/trade',
    name: 'Trade',
    element: <Trade />,
    layout: PrivateLayout,
    isPrivate: true
  },
  {
    path: '/profile',
    name: 'Profile',
    element: <Profile />,
    layout: PrivateLayout,
    isPrivate: true
  },
  {
    path: '/accounts/:accountId?',
    name: 'Accounts',
    element: <AccountsPage />,
    layout: PrivateLayout,
    isPrivate: true
  },
  {
    path: '/login',
    name: 'Login',
    element: <Login />,
    layout: AuthLayout,
    isPrivate: false
  },
  {
    path: '/savings',
    name: 'Savings',
    element: <Savings />,
    layout: PrivateLayout,
    isPrivate: true
  },
  {
    path: '/registration',
    name: 'Registration',
    element: <Registration />,
    layout: AuthLayout,
    isPrivate: false
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    element: <ForgotPassword />,
    layout: AuthLayout,
    isPrivate: false
  },
  {
    path: '/recovery/:token',
    name: 'Password Recovery',
    element: <PasswordRecovery />,
    layout: AuthLayout,
    isPrivate: false
  },
  {
    path: '/verify-account/:token',
    name: 'Verify Account',
    element: <VerifyAccount />,
    layout: AuthLayout,
    isPrivate: false
  },
  {
    path: '/',
    name: '',
    element: <></>,
    layout: PublicLayout,
    isPrivate: false
  },
  {
    path: '*',
    name: 'Page Not Found',
    element: <PageNotFound/>,
    layout: PublicLayout,
    isPrivate: false
  }
];
