import React, { type FC } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';

export const Success2FAIcon: FC<{ width: string }> = ({ width }) => {
  const [widthScreen] = useWindowSize();

  const isMobile = (widthScreen <= 600);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={isMobile ? ((width.length > 0) ? width : '40') : '100'} height={isMobile ? ((width.length > 0) ? width : '40') : '100'} viewBox="0 0 100 100" fill="none">
        <mask id="mask0_1640_1699" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="-1" y="0" width="101" height="100">
            <path d="M99.9932 0H-0.00683594V100H99.9932V0Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_1640_1699)">
            <mask id="mask1_1640_1699" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="1" y="2" width="98" height="96">
                <path d="M1.52246 2.61719H98.4645V97.3833H1.52246V2.61719Z" fill="white"/>
            </mask>
            <g mask="url(#mask1_1640_1699)">
                <g opacity="0.974087">
                    <path opacity="0.1" d="M50.0721 15.2295C69.4709 15.2295 85.2213 30.6263 85.2213 49.5897C85.2213 68.5532 69.4709 83.95 50.0721 83.95C30.6732 83.95 14.9229 68.5532 14.9229 49.5897C14.9229 30.6263 30.6732 15.2295 50.0721 15.2295Z" fill="var(--CFD-theme-System-Tertiary)"/>
                </g>
                <path d="M34.3672 50.8335L44.1182 60.1805C44.1182 60.1805 61.8533 42.2601 63.6613 40.4331" stroke="var(--CFD-theme-System-Tertiary)" strokeWidth="5.61609" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </g>
    </svg>
  )
}
