import React, { useState } from 'react';
import styles from './Delete2FA.module.css';
import { ButtonComponent, useSnackbar } from '../../../../../components';
import { delete2FA } from '../../../../../redux/reducers/twoFactorAuthentication';
import { useAppDispatch } from '../../../../../hooks/redux';
import { t } from 'i18next';

const Delete2FA: React.FC<{ hideDialog: any }> = ({ hideDialog }) => {
  const dispatch = useAppDispatch()
  const { handleOpen } = useSnackbar();
  const [code, setCode] = useState<string[]>(Array(6).fill(''));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === '') {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value !== '' && index < 5) {
        const nextInput = document.getElementById(`input-${index + 1}`);
        nextInput?.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number): void => {
    if (e.key === 'Backspace' && code[index] === '') {
      if (index > 0) {
        const prevInput = document.getElementById(`input-${index - 1}`);
        prevInput?.focus();
      }
    }
  };

  const handleSubmit = (): void => {
    const authCode = code.join('');

    if (authCode.length === 6) {
      dispatch(delete2FA({ code: authCode, handleOpen }));
      hideDialog();
      handleOpen({
        message: '2FA deleted',
        actionText: '',
        severity: 'success'
      })
    } else {
      handleOpen({
        message: 'Code must be 6 digit length',
        actionText: '',
        severity: 'error'
      })
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <span className={styles.heading}>
          {t('profile_page.settings.delete_google_2fa.title')}
        </span>
        <span className={styles.subHeading}>
          {t('profile_page.settings.delete_google_2fa.code_title')}
        </span>
        <div className={styles.inputContainer}>
          {code.map((digit, index) => (
            <input
              key={index}
              id={`input-${index}`}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => { handleChange(e, index); }}
              onKeyDown={(e) => { handleKeyDown(e, index); }}
              className={styles.digitInput}
            />
          ))}
        </div>
        <span className={styles.prompt}>
          {t('profile_page.settings.delete_google_2fa.code_tip')}
        </span>
        <div className={styles.buttonContainer}>
          <div className={styles.cancelButtonWrapper}>
            <ButtonComponent customInlineStyles={{ height: '36px' }} onClick={hideDialog} variant='outlined'>
              {t('profile_page.settings.delete_google_2fa.cancel_btn')}
            </ButtonComponent>
          </div>
          <div className={styles.deleteButtonWrapper}>
            <ButtonComponent customInlineStyles={{ height: '36px' }} onClick={handleSubmit} variant='contained'>
              {t('profile_page.settings.delete_google_2fa.delete_btn')}
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delete2FA;
