import { type IAssetSocketData } from '../../../../../../../interfaces';
import { type ResolutionString } from '@wisecryptoanalysis/charting_library';
import { type IBar } from '../datafeed/datafeed';
import { type TradingTerminalWidgetOptions } from '../TVChart';
import { socket } from '../../../../../../../web/socket';
import { parseFullSymbol } from '../datafeed/helper';

const userId = localStorage.getItem('uId') ?? null;

const channelToSubscription = new Map<string, any>();

const getNextDailyBarTime = (barTime: number): number => {
  const date = new Date(barTime * 1000);
  date.setDate(date.getDate() + 1);
  return date.getTime() / 1000;
}

export const getChannel = (): any => {
  const entry = channelToSubscription.entries().next().value;
  if (entry?.[1] !== undefined) {
    return entry[1];
  } else {
    return null;
  }
};

export const subscribeOnStream = (
  symbolInfo: TradingTerminalWidgetOptions,
  resolution: ResolutionString,
  onRealtimeCallback = (bar: IBar): void => {},
  subscriberUID: string,
  onResetCacheNeededCallback = (): void => {},
  lastDailyBar: string
): void => {
  if (!symbolInfo.isOpened) return;

  const parsedSymbol = parseFullSymbol(symbolInfo.full_name);

  const channelString = `${resolution}~${parsedSymbol?.exchange ?? ''}~${symbolInfo.brokerSymbol}`;
  const pairname = parsedSymbol !== null ? parsedSymbol.fromSymbol + parsedSymbol.toSymbol : '';

  const handler = {
    id: subscriberUID,
    callback: onRealtimeCallback
  };

  let subscriptionItem = channelToSubscription.get(channelString);

  if (subscriptionItem !== undefined) {
    subscriptionItem.handlers.push(handler);
    return;
  }

  subscriptionItem = {
    symbolInfo,
    onRealtimeCallback,
    onResetCacheNeededCallback,
    subscriberUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
    pairname,
    parsedSymbol,
    userId,
    uniqueChannelId: channelString
  };

  channelToSubscription.set(channelString, subscriptionItem);
  socket.on(`recieveCFDData&${symbolInfo.uniqueId}`, ({ prices, success, market }: { prices: IAssetSocketData[], success: boolean, market: string }) => {
    const { type, brokerSymbol } = symbolInfo;

    if (success && market === type) {
      const symbolData = prices.find(({ symbol }) => symbol === brokerSymbol);

      if (symbolData === undefined) return;

      const {
        bid, ask, time
      } = symbolData;
      const eventTime = new Date(time * 1000);

      if (bid === 0 && ask === 0) return;

      const channelString = `${resolution}~${parsedSymbol?.exchange ?? ''}~${brokerSymbol}`;
      const subscriptionItem = channelToSubscription.get(channelString);

      if (subscriptionItem === undefined) {
        return;
      }

      const lastDailyBar = subscriptionItem.lastDailyBar as IBar;
      const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time ?? Date.now());
      let bar: IBar = {
        time: 0,
        open: 0,
        high: 0,
        low: 0,
        close: 0
      };

      if (eventTime.getTime() >= nextDailyBarTime) {
        bar = {
          time: nextDailyBarTime,
          open: bid,
          high: bid,
          low: bid,
          close: bid
        //   isBarClosed: true,
        };
      } else {
        bar = {
          ...lastDailyBar,
          high: Math.max(lastDailyBar.high ?? 0, bid),
          low: Math.min(lastDailyBar.low ?? 0, bid),
          close: bid
        };
      }

      subscriptionItem.lastDailyBar = bar;

      subscriptionItem.handlers.forEach((handler: { callback: (arg0: IBar) => void }) => {
        handler.callback(bar);
      });
    }
  });
}

export const unsubscribeFromStream = (subscriberUID: string): void => {
  // eslint-disable-next-line no-restricted-syntax
  for (const channelString of Array.from(channelToSubscription.keys())) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex((handler: { id: string }) => handler.id === subscriberUID);

    if (handlerIndex !== -1) {
      // Remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        channelToSubscription.delete(channelString);

        break;
      }
    }
  }
}
