import React, { type FC, useState, type ChangeEvent, useMemo, useEffect, type FocusEvent, type Dispatch, type SetStateAction } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { InputComponent, SearchSelectComponent } from '../../../../components';
import { getFiatCurrencies } from '../../../../redux/reducers/currencies';
import { t } from 'i18next';
import { type IBankData } from '../BankWithdrawalSection/BankWithdrawalSection';
import { type OptionInterface } from '../../../../interfaces';

import style from './AddBankFormComponent.module.css';

interface InterfaceAddBankFormComponent {
  bankData: IBankData
  setBankData: Dispatch<SetStateAction<IBankData>>
  currencySelected: OptionInterface
  setCurrencySelected: (el: OptionInterface) => void
  onFocus: (event: FocusEvent<HTMLInputElement>) => void
}

export const AddBankFormComponent: FC<InterfaceAddBankFormComponent> = ({
  currencySelected,
  setCurrencySelected,
  onFocus,
  bankData,
  setBankData
}) => {
  const dispatch = useAppDispatch();
  const [ibanError, setIbanError] = useState<boolean>(false);
  const currencies = useAppSelector((state) => state.currencies);

  const options = useMemo(() => {
    return currencies.map((currency) => ({ label: currency.name, value: currency._id, symbol: currency.symbol }))
  }, [currencies]);

  useEffect(() => {
    dispatch(getFiatCurrencies());
  }, []);

  const ibanHandleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target: { value: ibanValue } } = event;

    if (ibanValue.length <= 34) {
      setBankData({ ...bankData, iban: ibanValue })
      setIbanError(false);
    } else {
      setIbanError(true);
    }
  };

  const searchCurrencyHandler = (value: string | OptionInterface | null): void => {
    if (value === null || typeof value === 'string') return;
    setCurrencySelected(value)
  }

  const clearCurrencySelect = (): void => {
    setCurrencySelected({
      value: '',
      label: '',
      symbol: ''
    });
  }

  return (
  <>
    <div className={ style.wrapper }>
      <div className={ style.formContainer }>
        <div className={ style.inputContainer }>
          <label className={ style.label }>{t('labels.bank_name')}</label>
          <InputComponent
            onFocus={ onFocus }
            value={ bankData.bankName }
            setValue={ (bankNameValue) => { setBankData((prevSate) => ({ ...prevSate, bankName: bankNameValue })); } }
            placeholder={ `${t('labels.enter_the-bank_name')}` }
          />
        </div>
        <div className={ style.inputContainer }>
          <label htmlFor='selectCurrency' className={ style.label }>{t('labels.currency')}</label>
          <SearchSelectComponent
            options={ options }
            value={ currencySelected }
            onSearch={ searchCurrencyHandler }
            clearState={ clearCurrencySelect }
            onFocus={ onFocus }
            id='selectCurrency'
            placeholder={ `${t('labels.select_currency')}` }
          />
        </div>
        <div className={ style.inputContainer }>
          <label className={ style.label }>{t('labels.iban')}</label>
          <InputComponent
            onFocus={ onFocus }
            value={ bankData.iban?.toUpperCase() }
            setValue= {(ibanValue) => { setBankData((prevSate) => ({ ...prevSate, iban: ibanValue.toUpperCase() })); } }
            errorText={t('labels.iban_error')}
            error={ ibanError }
            onChange={ ibanHandleChange }
            placeholder={ `${t('labels.enter_international_account_number')}` }
          />
        </div>
        <div className={ style.inputContainer }>
          <label className={ style.label }>{t('labels.account_number')}</label>
          <InputComponent
            onFocus={ onFocus }
            value={ bankData.accountNumber }
            setValue={ (accountNumberValue) => { setBankData((prevSate) => ({ ...prevSate, accountNumber: accountNumberValue })); } }
            placeholder={ `${t('labels.enter_account_number')}` }
          />
        </div>
        <div className={ style.inputContainer }>
          <label className={ style.label }>{t('labels.bank_address')}</label>
          <InputComponent
            onFocus={ onFocus }
            value={ bankData.bankAddress }
            setValue={ (bankAddressValue) => { setBankData((prevSate) => ({ ...prevSate, bankAddress: bankAddressValue })); } }
            placeholder={ `${t('labels.enter_bank_address')}` }
          />
        </div>
        <div className={ style.inputContainer }>
          <label className={ style.label }>{t('labels.swift')}</label>
          <InputComponent
            onFocus={ onFocus }
            value={ bankData.swift?.toUpperCase() }
            setValue={ (swiftValue) => { setBankData((prevSate) => ({ ...prevSate, swift: swiftValue.toUpperCase() })); } }
            placeholder={ `${t('labels.enter_SWIFT_code')}` }
          />
        </div>
      </div>
    </div>
  </>
  )
}
