import React, { useEffect, useState, type FC } from 'react';
import { TableHead, styled, TableRow, TableCell } from '@mui/material';

import { FilterTableIcon } from '../../../../components/icons/FilterTableIcon';
import { useWindowSize } from '../../../../hooks/useWindowSize';

import style from './StyledAccountsTableHead.module.css';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getTransactionsHistory } from '../../../../redux/reducers/transactionsHistory';
import { type IAccount } from '../../../../redux/reducers/accounts';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: 'var(--CFD-theme-System-Surface)',
  color: 'var(--CFD-theme-System-OnSurface)'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: 'var(--CFD-theme-System-Surface)',
  color: 'var(--CFD-theme-System-Outline)',
  fontFamily: 'Arial',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '16px',
  padding: '8px 10px',
  letterSpacing: '0.5px',
  textIndent: '16px',
  borderBottom: '1px solid var(--CFD-theme-System-OutlineVariant)'
}));

export type SortStatusType = 'desc' | 'idle' | 'asc';

interface StyledAccountsTableHeadProps {
  selectedAccount: IAccount | null
}

export const StyledAccountsTableHead: FC<StyledAccountsTableHeadProps> = ({ selectedAccount }) => {
  const dispatch = useAppDispatch();
  const [widthScreen] = useWindowSize();

  const { _id: userId } = useAppSelector((state) => state.user);

  const [sortField, setSortField] = useState<string>('createdAt');
  const [sortOrder, setSortOrder] = useState<SortStatusType>('desc');

  useEffect(() => {
    if (userId !== undefined && userId.length > 0 && selectedAccount !== null) {
      dispatch(getTransactionsHistory({
        userId,
        accountId: selectedAccount._id,
        sortField,
        sortOrder
      }));
    }
  }, [sortField, sortOrder])

  const dateFilterButtonHandler = (): void => {
    setSortField('createdAt');
    setSortOrder(sortField === 'createdAt' ? (sortOrder === 'desc' ? 'asc' : 'desc') : 'asc');
  }

  const amountFilterButtonHandler = (): void => {
    setSortField('amount');
    setSortOrder(sortField === 'amount' ? (sortOrder === 'desc' ? 'asc' : 'desc') : 'asc');
  }

  const isMobile = (widthScreen > 905);

  const dateSortStatus: SortStatusType = sortField === 'createdAt' ? sortOrder : 'idle';
  const amountSortStatus: SortStatusType = sortField === 'amount' ? sortOrder : 'idle';

  return (
    <TableHead>
      <StyledTableRow>
       { isMobile ? <StyledTableCell>{t('labels.id')}</StyledTableCell> : null }
        <StyledTableCell>
          <div className={ style.cellTextContainer } onClick={ dateFilterButtonHandler } style={{ textIndent: !isMobile ? '6px' : '16px' }}>
            {t('labels.date')}
            <button className={ style.cellTextBtnIcon }>
              <FilterTableIcon status={ dateSortStatus } />
            </button>
          </div>
        </StyledTableCell>
        { !isMobile ? <StyledTableCell>{t('labels.status')}</StyledTableCell> : null }
        { isMobile ? <StyledTableCell>{t('labels.type')}</StyledTableCell> : null }
        <StyledTableCell >
          <div className={ style.cellTextContainer } onClick={ amountFilterButtonHandler } style={{ justifyContent: isMobile ? 'start' : 'end' }}>
            {t('labels.amount')}
            <button className={ style.cellTextBtnIcon }>
              <FilterTableIcon status={ amountSortStatus } />
            </button>
          </div>
        </StyledTableCell>
        { isMobile ? <StyledTableCell>{t('labels.currency')}</StyledTableCell> : null }
        { isMobile ? <StyledTableCell>{t('labels.status')}</StyledTableCell> : null }
      </StyledTableRow>
    </TableHead>
  )
}
