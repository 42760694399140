import React, { type FC, useMemo, type Dispatch, type SetStateAction } from 'react';
import { useAppSelector } from '../../../../hooks/redux';
import { DividerComponent } from '../../../../components';
import { PreferredAssets } from './components/PreferredAssets/PreferredAssets';
import { TVChart } from './components/TVChart/TVChart';
import styles from './Chart.module.css';

interface IChart {
  uniqueId: number
  isOpened: boolean
  isNewsShown: boolean
  isMarketShown: boolean
  setIsMarketShown: Dispatch<SetStateAction<boolean>>
  setIsNewsShown: Dispatch<SetStateAction<boolean>>
  calcHeight: number
}

export const Chart: FC<IChart> = ({ uniqueId, isOpened, isNewsShown, isMarketShown, setIsMarketShown, setIsNewsShown }) => {
  const { assetInfo } = useAppSelector((state) => state.cfd);
  const asset = assetInfo?.type.name === 'forex' || assetInfo?.type.name === 'crypto' ? `${assetInfo.primary}/${assetInfo.secondary}` : assetInfo.primary;

  const TVChartComponent = useMemo(() => {
    return String(asset ?? '').length > 0 ? <TVChart asset={asset} brokerSymbol={assetInfo.brokerSymbol} uniqueId={uniqueId} isOpened={isOpened} /> : null;
  }, [asset, uniqueId])

  return (
      <div className={styles.chartContainer}>
          <div className={styles.chartContainerHeader}>
              <PreferredAssets
                isNewsShown={isNewsShown}
                isMarketShown={isMarketShown}
                setIsMarketShown={setIsMarketShown}
                setIsNewsShown={setIsNewsShown}
              />
          </div>
          <DividerComponent />
          {TVChartComponent}
      </div>
  )
}
