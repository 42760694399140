import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type IAssetSocketData } from '../../interfaces/AssetSocketDataInterface';

export interface IMarketData {
  market: string
  success: boolean
  prices: IAssetSocketData[]
}

interface InitState {
  marketData: IMarketData
  activePnL: number
  activeMargin: number
}

const initialState: InitState = {
  marketData: {
    market: '',
    success: false,
    prices: []
  },
  activePnL: 0,
  activeMargin: 0
};

export const marketDataSlice = createSlice({
  name: 'marketData',
  initialState,
  reducers: {
    setMarketData (state, action: PayloadAction<IMarketData>) {
      state.marketData = action.payload;
    },
    setActivePnL (state, action: PayloadAction<number>) {
      state.activePnL = action.payload;
    },
    setActiveMargin (state, action: PayloadAction<number>) {
      state.activeMargin = action.payload;
    }
  }
});

export const { setMarketData, setActivePnL, setActiveMargin } = marketDataSlice.actions;

export default marketDataSlice.reducer;
