/* eslint-disable @typescript-eslint/no-floating-promises */
import i18n, { type Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import EN_TRANSLATION from './translations/en_translation.json';
import FR_TRANSLATION from './translations/fr_translation.json';
import RU_TRANSLATION from './translations/ru_translation.json';
import PL_TRANSLATION from './translations/pl_translation.json';
import SP_TRANSLATION from './translations/sp_translation.json';
import DE_TRANSLATION from './translations/de_translation.json';
import NLD_TRANSLATION from './translations/nld_translation.json';
import IT_TRANSLATION from './translations/it_translation.json';
import TR_TRANSLATION from './translations/tr_translation.json';
import AR_TRANSLATION from './translations/ar_translation.json';

const resources: Resource = {
  en: {
    translation: EN_TRANSLATION
  },
  fr: {
    translation: FR_TRANSLATION
  },
  ru: {
    translation: RU_TRANSLATION
  },
  pl: {
    translation: PL_TRANSLATION
  },
  sp: {
    translation: SP_TRANSLATION
  },
  de: {
    translation: DE_TRANSLATION
  },
  nld: {
    translation: NLD_TRANSLATION
  },
  it: {
    translation: IT_TRANSLATION
  },
  tr: {
    translation: TR_TRANSLATION
  },
  ar: {
    translation: AR_TRANSLATION
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    resources
  });

export default i18n;
