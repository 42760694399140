import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type LanguageObjectType } from '../selectors/language';
import { getLanguagesCodes, languageAPI } from '../api/languageApi';
import USA_Icon from '../../i18n/icons/USA.png'
import FranceIcon from '../../i18n/icons/France.png'
import PolandIcon from '../../i18n/icons/Poland.png'
import RussiaIcon from '../../i18n/icons/Russia.png'
import SpanishIcon from '../../i18n/icons/Spanish.png'
import GermanyIcon from '../../i18n/icons/Germany.png'
import NetherlandsIcon from '../../i18n/icons/Netherlands.png'
import ItalyIcon from '../../i18n/icons/Itali.svg';
import TurkeyIcon from '../../i18n/icons/Türkiye (TRY).svg';
import ArabicIcon from '../../i18n/icons/Saudi Arabia (SA).svg';

const iconData = {
  en: USA_Icon,
  fr: FranceIcon,
  pl: PolandIcon,
  ru: RussiaIcon,
  sp: SpanishIcon,
  de: GermanyIcon,
  nld: NetherlandsIcon,
  it: ItalyIcon,
  tr: TurkeyIcon,
  ar: ArabicIcon
}

interface InitState {
  language: string
  languages: LanguageObjectType[]
}

const localStorageLanguage = localStorage.getItem('i18nextLng') ?? 'en'

const initialState: InitState = {
  language: localStorageLanguage,
  languages: []
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage (state, action: PayloadAction<string>) {
      localStorage.setItem('i18nextLng', action.payload)
      state.language = action.payload;
    },
    setLanguages (state, action: PayloadAction<LanguageObjectType[]>) {
      state.languages = action.payload;
    }
  }
});

export const { setLanguage, setLanguages } = languageSlice.actions;

type LanguageCodeType = keyof typeof iconData;

export const getLanguages = createAsyncThunk('language/getLanguages', async (_: undefined, { dispatch }) => {
  try {
    const languagesData = await languageAPI.getLanguages()
    const preparedLangData = languagesData.map(({ _id, name, code }: { _id: string, name: string, code: string }) => ({
      _id,
      code,
      language: name,
      icon: iconData[code as LanguageCodeType]
    }))

    dispatch(setLanguages(preparedLangData))
  } catch (error) {
    console.log(error)
  }
});

export const defineDefaultLangCodeCorrect = createAsyncThunk(
  'language/defineDefaultLangCodeCorrect',
  async (_: undefined, { dispatch }) => {
    const languagesCodes = await getLanguagesCodes()
    const isLanguageFormatSuits = languagesCodes.includes(localStorageLanguage)

    dispatch(setLanguage(isLanguageFormatSuits ? localStorageLanguage : 'en'))
  }
)

export default languageSlice.reducer;
