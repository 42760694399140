import React, { type FC } from 'react';

import BinanceIcon from '../../../assets/images/Binance.svg'
import BingX from '../../../assets/images/BingX.svg';
import ByBit from '../../../assets/images/Bybit.svg';
import OKX from '../../../assets/images/OKX.svg';
import MEXC from '../../../assets/images/MEXC.svg';

import styles from '../RunningAdvertisementLineComponent.module.css';

export const AdvertisementLabels: FC = () => {
  return (
    <>
      <svg className={ `${styles.byBitSvg} ${styles.svgHeight}` }>
        <use xlinkHref={`${ByBit}#byBit`} />
      </svg>
      <svg className={ `${styles.binanceSvg} ${styles.svgHeight}` }>
        <use xlinkHref={`${BinanceIcon}#binance`} />
      </svg>
      <svg className={ `${styles.bingXSvg} ${styles.svgHeight}` }>
        <use xlinkHref={`${BingX}#bingX`} />
      </svg>
      <svg className={ `${styles.okxSvg} ${styles.svgHeight}` }>
        <use xlinkHref={`${OKX}#okx`} />
      </svg>
      <svg className={ `${styles.mecxSvg} ${styles.svgHeight}` }>
        <use xlinkHref={`${MEXC}#mexc`} />
      </svg>
    </>
  )
}
