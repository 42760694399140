import React, { type FC, type FocusEvent } from 'react';
import { t } from 'i18next';
import { useAppSelector } from '../../../../hooks/redux';
import { InputComponent, SearchSelectComponent } from '../../../../components';
import { type OptionInterface } from '../../../../interfaces';

import style from './AddCryptoFormComponent.module.css';

interface InterfaceAddCryptoFormComponent {
  cryptoSelect: OptionInterface
  setCryptoSelect: (el: OptionInterface) => void
  networkSelect: OptionInterface
  setNetworkSelect: (el: OptionInterface) => void
  walletAddress: string
  setWalletAddress: (el: string) => void
  networkOptions: OptionInterface[]
  currencyOptions: OptionInterface[]
  onFocus: (el: FocusEvent<HTMLInputElement>) => void
}

export const AddCryptoFormComponent: FC<InterfaceAddCryptoFormComponent> = ({
  cryptoSelect,
  setCryptoSelect,
  networkSelect,
  setNetworkSelect,
  walletAddress,
  setWalletAddress,
  networkOptions,
  currencyOptions,
  onFocus
}) => {
  const networks = useAppSelector((state) => state.networks);

  const searchCryptoHandler = (value: string | OptionInterface | null): void => {
    if (value === null || typeof value === 'string') return;
    setCryptoSelect(value)
  }

  const searchNetworkHandler = (value: string | OptionInterface | null): void => {
    if (value === null || typeof value === 'string') return;
    setNetworkSelect(value)
  }

  const clearCryptoSelect = (): void => {
    setCryptoSelect({
      value: '',
      label: '',
      symbol: ''
    });
  }

  const clearNetworkSelect = (): void => {
    setNetworkSelect({
      value: '',
      label: ''
    });
  }

  return (
  <>
    <div className={ style.wrapper }>
      <div className={ style.formContainer }>
        <div className={ style.inputContainer }>
          <label htmlFor='coinCurrency' className={ style.label }>{t('labels.currency')}</label>
          <SearchSelectComponent
            options={ currencyOptions }
            value={ cryptoSelect }
            onSearch={ searchCryptoHandler }
            clearState={ clearCryptoSelect }
            onFocus={ onFocus }
            id='coinCurrency'
            placeholder={ `${t('labels.select_coin')}` }
          />
        </div>
        {
          (cryptoSelect.value.length > 0 && networks.length > 0) && (
            <div className={ style.inputContainer }>
              <label htmlFor='networkCurrency' className={ style.label }>{t('labels.network')}</label>
              <SearchSelectComponent
                options={ networkOptions }
                value={ networkSelect }
                onSearch={ searchNetworkHandler }
                clearState={ clearNetworkSelect }
                onFocus={ onFocus }
                id='networkCurrency'
                placeholder={ `${t('labels.select_network')}` }
              />
            </div>
          )
        }
        {
          cryptoSelect.value.length > 0 && (
            <div className={ style.inputContainer }>
              <label htmlFor='walletAdress' className={ style.label }>{t('labels.wallet_address')}</label>
              <InputComponent
                value={ walletAddress }
                setValue={ setWalletAddress }
                id='walletAdress'
                onFocus={ onFocus }
                placeholder={ `${t('labels.enter_your_wallet_address')}` }
              />
            </div>
          )
        }
      </div>
    </div>
  </>
  )
}
