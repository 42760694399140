import { type IBasicDataFeed, type LibrarySymbolInfo, type ResolutionString, type PeriodParams, type SymbolResolveExtension, type Bar } from '@wisecryptoanalysis/charting_library';
import { type TradingTerminalWidgetOptions } from '../TVChart';
import { subscribeOnStream, unsubscribeFromStream, getChannel } from '../streaming/streaming';
import { apiHelper } from '../../../../../../../redux/api/apiHelper';
import { parseFullSymbol } from './helper';
import { socket } from '../../../../../../../web/socket';

const configurationData = {
  supported_resolutions: ['1', '5', '15', '30', '1H', '2H', '4H', '6H', '1D', '1W', '1M'],
  exchanges: [
    {
      value: `${process.env.REACT_APP_BRANDNAME}`,
      name: `${process.env.REACT_APP_BRANDNAME}`,
      desc: `${process.env.REACT_APP_BRANDNAME}`
    }
  ],

  symbols_types: [
    {
      name: 'cfd',
      value: 'cfd'
    }
  ]
};

interface IResolution {
  multiplier: number
  timespan: string
}

export interface IBar extends Bar {
  time: number
  open: number
  close: number
  high: number
  low: number
}

interface ExtendedLibrarySymbolInfo extends LibrarySymbolInfo {
  brokerSymbol: string
  typeObject: any
  uniqueId: string
  isOpened: number
}

const lastBarsCache = new Map();

const subscribeOnListener = (): void => {
  const userId = localStorage.getItem('uId') ?? null;
  if (userId !== null) {
    socket.on(`tsCFDListener${userId}`, () => {
      const channel = getChannel();

      if (channel !== null) {
        unsubscribeFromStream(channel.subscriberUID as string);
        subscribeOnStream(
          channel.symbolInfo as TradingTerminalWidgetOptions,
          channel.resolution as ResolutionString,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          channel?.onRealtimeCallback,
          channel.subscriberUID as string,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          channel.onResetCacheNeededCallback,
          channel.lastDailyBar as string
        );
      }
    });
  }
}

const resolutionToTimeSpan = (value: string): IResolution => {
  const mapping: Record<string | number, IResolution> = {
    1: { multiplier: 1, timespan: 'minute' },
    5: { multiplier: 5, timespan: 'minute' },
    15: { multiplier: 15, timespan: 'minute' },
    30: { multiplier: 30, timespan: 'minute' },
    60: { multiplier: 1, timespan: 'hour' },
    120: { multiplier: 2, timespan: 'hour' },
    240: { multiplier: 4, timespan: 'hour' },
    360: { multiplier: 6, timespan: 'hour' },
    '1D': { multiplier: 1, timespan: 'day' },
    '1W': { multiplier: 1, timespan: 'week' },
    '1M': { multiplier: 1, timespan: 'month' }
  };

  return mapping[value];
};

const testMakeApiRequest = async (urlParameters: any): Promise<any[] | undefined> => {
  const { interval, to } = urlParameters;
  const { multiplier, timespan } = resolutionToTimeSpan(interval as string);

  try {
    const response = await apiHelper({
      method: 'get',
      path: `/chart/get-cfd-bars?symbol=${urlParameters.symbol.replace('#', '%23')}&multiplier=${multiplier}&timespan=${timespan}&userId=${urlParameters.userId}&to=${to}`
    });

    return response.data.aggregates;
  } catch (error: any) {
    throw new Error(`Can not get bars: ${error.status}`);
  }
};

export const getPairInfo = async (symbol: string): Promise<any> => {
  try {
    const res = await apiHelper({ method: 'get', path: `/cfd-setting/${symbol}` });

    return res.data.cfdSetting;
  } catch (error) {
    console.error(error);
  }
};

export default class MyCustomDataFeed implements IBasicDataFeed {
  options: any;
  constructor (options: any) {
    this.options = options;
  }

  onReady = (callback: any): void => {
    subscribeOnListener();
    setTimeout(() => callback(configurationData));
  };

  getBars = async (
    symbolInfo: ExtendedLibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onHistoryCallback = (bars: IBar[], metaData: { noData: boolean }): void => {},
    onErrorCallback = (er: any): void => {}
  ): Promise<void> => {
    const userId = localStorage.getItem('uId') ?? null;
    const { firstDataRequest, from, to } = periodParams;
    const parsedSymbol = parseFullSymbol(symbolInfo.full_name);

    const urlParameters = {
      to,
      fromSymbol: parsedSymbol?.fromSymbol ?? '',
      toSymbol: parsedSymbol?.toSymbol ?? '',
      interval: resolution,
      symbol: symbolInfo.brokerSymbol,
      type: symbolInfo.typeObject,
      market: 'CFD',
      userId
    };

    try {
      const data = await testMakeApiRequest(urlParameters);
      let bars: IBar[] = [];

      data?.forEach((bar) => {
        if (bar.time >= from && bar.time < to) {
          bars = [...bars, {
            time: bar.time * 1000,
            low: bar.low,
            high: bar.high,
            open: bar.open,
            close: bar.close
          }];
        }
      });

      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.full_name, { ...bars[bars.length - 1] });
      }

      if (bars.length < 1) {
        onHistoryCallback([], { noData: true });
      } else {
        onHistoryCallback(bars, { noData: false });
      }
    } catch (error) {
      console.error('[getBars]: Get error', error);
      onErrorCallback(error);
    }
  };

  searchSymbols = (): void => {};

  resolveSymbol = async (
    symbolName: string,
    onSymbolResolvedCallback = (symbolInfo: ExtendedLibrarySymbolInfo): void => {},
    onError: (error: string) => void, // Added to handle errors
    extension?: SymbolResolveExtension
  ): Promise<void> => {
    try {
      const parsedSymbol = parseFullSymbol(symbolName);
      const description = ((parsedSymbol?.toSymbol) != null) ? parsedSymbol.fromSymbol + parsedSymbol.toSymbol : parsedSymbol?.fromSymbol;
      const pairInfo = await getPairInfo(description ?? '');

      const symbolInfo: ExtendedLibrarySymbolInfo = {
        ticker: symbolName,
        name: symbolName,
        description: description ?? '',
        type: pairInfo.type.name,
        timezone: 'Etc/UTC',
        session: '24x7',
        exchange: parsedSymbol?.exchange ?? '',
        minmov: 1,
        pricescale: pairInfo.scale,
        has_intraday: true,
        has_weekly_and_monthly: true,
        supported_resolutions: configurationData.supported_resolutions as ResolutionString[],
        volume_precision: 5,
        data_status: 'streaming',
        typeObject: pairInfo.type,
        brokerSymbol: pairInfo.brokerSymbol,
        uniqueId: this.options.uniqueId,
        isOpened: this.options.isOpened,
        full_name: '',
        listed_exchange: '',
        format: 'price'
      };
      onSymbolResolvedCallback(symbolInfo);
    } catch (error) {
      console.error('[resolveSymbol]: Error', error);
    }
  };

  subscribeBars = (
    symbolInfo: TradingTerminalWidgetOptions,
    resolution: ResolutionString,
    onRealtimeCallback = (bar: IBar): void => {},
    subscriberUID: string,
    onResetCacheNeededCallback = (): void => {}
  ): void => {
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.full_name) as string
    );
  };

  unsubscribeBars = (subscriberUID: string): void => {
    unsubscribeFromStream(subscriberUID);
  };
}
