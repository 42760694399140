import React, { type FC } from 'react';
import { t } from 'i18next';

import InfoOutline from '../../../../assets/images/InfoOutline.svg';

import styles from './WarningBetweenSymbolComponent.module.css';

interface IWarningBetweenSymbolComponent {
  selectedAccount?: string
  currencySymbol?: string
  sectionName: 'Bank' | 'Crypto'
  cryptoAmount?: string
  convertCryptoAmount?: number
}

export const WarningBetweenSymbolComponent: FC<IWarningBetweenSymbolComponent> = ({ selectedAccount, currencySymbol, sectionName, cryptoAmount, convertCryptoAmount }) => {
  return (
    <div className={ styles.wrapper }>
      { sectionName === 'Bank'
        ? (
            <>
              <div className={ styles.infoSign }>
                <svg>
                  <use xlinkHref={`${InfoOutline}#InfoOutline`}/>
                </svg>
              </div>
              <p className={ styles.titleMessage }>
                {t('labels.warning_message', { currencySymbol, selectedAccount })}
              </p></>
          )
        : (
            <div className={ styles.cryptoContainer }>
              <p className={ styles.titleMessage }>
                {`${t('labels.total_withdrawal')}: ${cryptoAmount}`}
              </p>
              <p className={ styles.titleMessage }>
                {`${t('labels.conversion_approx')}: ${convertCryptoAmount} ${currencySymbol}`}
              </p>
            </div>
          )
      }

    </div>
  )
}
