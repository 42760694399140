import React, { type FocusEvent, useState, type FC, type SyntheticEvent } from 'react';
import { type SelectChangeEvent } from '@mui/material/Select';
import { MenuItem, TextField, Autocomplete, Paper, IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';

import { getAccountIconBySymbol } from '../../helpers/util';
import { type OptionInterface } from '../../interfaces';

import style from './SearchSelectComponent.module.css';

interface ISearchSelectComponent {
  options: OptionInterface[]
  value: string | OptionInterface
  id?: string
  height?: number
  clearState: () => void
  onSearch: (value: string | OptionInterface | null) => void
  onChange?: (event: SelectChangeEvent<unknown>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  placeholder?: string
}

const CustomizedInput = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    paddingLeft: '16px',
    height: '40px',
    borderRadius: '4px',
    border: '1px solid var(--CFD-theme-System-Outline)',
    paddingRight: '30px !important',
    outline: 'unset',
    '&.Mui-focused': {
      border: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '3px solid var(--CFD-theme-System-Primary)'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    width: '18px',
    height: '18px'
  },
  '& .MuiIconButton-root': {
    height: '18px',
    width: '18px',
    right: '5px'
  },
  '& .MuiInputBase-root .MuiInputBase-input': {
    padding: 0,
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left'
  }
}));

const CustomizedMenuItem = styled(MenuItem)(() => ({
  color: 'var(--CFD-theme-System-OnSurface)',
  backgroundColor: 'var(--CFD-theme-Surface-3)',
  fontFamily: 'Arial',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  padding: '8px 12px',
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-Surface-5) !important'
  },
  '&.Mui-selected': {
    backgroundColor: 'var(--CFD-theme-Surface-5) !important'
  }
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  backgroundColor: 'var(--CFD-theme-Surface-3)',
  color: 'var(--CFD-theme-System-OnSurface) !important',
  borderRadius: '4px',
  maxHeight: '220px',
  padding: 0,
  '& .MuiAutocomplete-noOptions': {
    color: 'var(--CFD-theme-System-OnSurface) !important'
  }
}));

const handleSearch = (
  event: SyntheticEvent<Element, Event>,
  value: string | OptionInterface | null,
  reason: string,
  onSearch: (value: string | OptionInterface | null) => void
): void => {
  if (typeof onSearch === 'function') onSearch(value);
}

export const SearchSelectComponent: FC<ISearchSelectComponent> = ({
  options = [],
  value = '',
  id = '',
  onSearch = () => {},
  onFocus,
  onBlur,
  clearState,
  placeholder = ''
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    setIsFocused(true);
    setIsOpen(true);
    onFocus?.(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    setIsFocused(false);
    setIsOpen(false);
    onBlur?.(event);
  };

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const clearValue = (): void => {
    clearState();
    setIsOpen(false);
  };

  return (
    <Autocomplete
      id={id}
      clearOnBlur
      clearIcon={null}
      PaperComponent={CustomPaper}
      options={options}
      noOptionsText={t('labels.no_options')}
      value={value as OptionInterface}
      onFocus={handleFocus}
      onBlur={handleBlur}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      sx={{
        '& input': {
          backgroundColor: 'transparent',
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px inherit inset',
            WebkitTextFillColor: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
            WebkitBackgroundClip: 'text'
          }
        },
        '& .MuiAutocomplete-noOptions': {
          color: 'red !important',
          fontWeight: 'bold'
        }
      }}
      onChange={(event, newValue, reason) => {
        if (reason === 'clear') {
          clearValue();
        } else {
          handleSearch(event, newValue, reason, onSearch);
        }
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'clear') {
          clearValue();
        }
      }}
      renderOption={(props, option) => {
        const { ...optionProps } = props;
        return (
          <CustomizedMenuItem
            {...optionProps}
          >
            <div className={ style.currencyInfoContainer }>
              {
                option?.symbol && <img
                  src={getAccountIconBySymbol((option.symbol as string) ?? '')}
                  className={style.currencyIcon}
                />
              }
              {option.label}
            </div>
          </CustomizedMenuItem>
        );
      }}
      renderInput={(params) => (
        <CustomizedInput
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              value && typeof value === 'object' && value.symbol
                ? (
                  <img
                    src={getAccountIconBySymbol(value?.symbol as string)}
                    className={style.currencyIconInput}
                    alt="Selected Currency Icon"
                  />
                  )
                : null
            ),
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {value && typeof value === 'object' && value.value.length > 0 && (
                  <IconButton
                    onClick={clearValue}
                    sx={{
                      border: '2px solid var(--CFD-theme-System-OnSurfaceVariant)',
                      borderRadius: '50%'
                    }}
                    edge="end"
                    size="small"
                  >
                    <Clear sx={{ width: '16px !important', height: '16px !important' }}/>
                  </IconButton>
                )}
              </>
            ),
            inputProps: {
              ...params.inputProps,
              autoComplete: 'new-password'
            }
          }}
        />
      )}
    />
  );
}
