import { type StatisticsInterface } from '../reducers/profile';
import { apiHelper } from './apiHelper';

export const profileAPI = {
  async getBalanceChartData (accountId: string, from?: string, to?: string): Promise<any> {
    if (accountId === undefined || accountId === null || accountId === '') {
      throw new Error('profileAPI -> getPieChartData:  accountId is not provided');
    }

    if (from === undefined) {
      const currentDate = new Date();
      from = getDateOfMonthBefore(currentDate)
    }

    if (to === undefined) {
      const currentDate = new Date();
      to = endDateOfDay(currentDate)
    }

    const response = await apiHelper({
      method: 'get',
      path: '/cfd-order/get-balance-chart',
      params: {
        accountId,
        timeFrom: from,
        timeTo: to
      }
    });

    const result: { success?: boolean, data: any[] } = response.status === 200 ? response.data : [];

    if (!(result?.success ?? false)) {
      throw new Error('Some error happened | profileAPI -> getPieChartData');
    }

    return result.data;
  },

  async getPieChartData (accountId: string, from?: string, to?: string): Promise<any> {
    if (accountId === undefined || accountId === null || accountId === '') {
      throw new Error('profileAPI -> getPieChartData:  accountId is not provided');
    }

    if (from === undefined) {
      const currentDate = new Date();
      from = getDateOfMonthBefore(currentDate)
    }

    if (to === undefined) {
      const currentDate = new Date();
      to = endDateOfDay(currentDate)
    }

    const response = await apiHelper({
      method: 'get',
      path: '/cfd-order/history-by-account-id',
      params: {
        accountId
      }
    });

    const result: { success?: boolean, data: any[] } = response.status === 200 ? response.data : [];

    if (!(result?.success ?? false)) {
      throw new Error('Some error happened | profileAPI -> getPieChartData');
    }

    return result.data;
  },

  async updateUserPassword (userId: string, oldPassword: string, password: string): Promise<any> {
    if (userId === '') {
      throw new Error('profileAPI -> updateUserPassword:  user is not provided');
    }

    const response = await apiHelper({
      method: 'put',
      path: `/user/change-password/${userId}`,
      data: {
        oldPassword,
        password
      }
    });

    const result: any = response.status === 200 ? response.data : [];

    if (!(result?.status === 200 || false)) {
      throw new Error('Some error happened | profileAPI -> updateUserPassword');
    }

    return result.message;
  },

  async updateUserInfo (userId: string, data: any): Promise<any> {
    if (userId === '') {
      throw new Error('profileAPI -> updateUserInfo:  user is not provided');
    }

    const response = await apiHelper({
      method: 'put',
      path: `/user/${userId}`,
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        fullAddress: data.fullAddress,
        countryCode: data.countryCode
      }
    });

    const result: any = response.status === 200 ? response.data : [];

    return result.message;
  },

  async getAccountStatistics (accountId: string): Promise<any> {
    if (accountId.length === 0) {
      throw new Error('profileAPI -> getAccountStatistics:  accountId is not provided');
    }

    const response = await apiHelper({
      method: 'get',
      path: `/cfd-account/statistic/${accountId}`
    });

    const result: { success?: boolean, statistic: StatisticsInterface } = response.status === 200 ? response.data : {};

    if (!(result?.success ?? false)) {
      throw new Error('Some error happened | profileAPI -> getAccountStatistics');
    }

    return result.statistic;
  }
};

const getDateOfMonthBefore = (date: any): string => {
  const year = date.getFullYear();
  const month = (date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}T00:00:00Z`;
};

const endDateOfDay = (date: any): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}T23:59:59Z`;
};
