import React, { type FC, useState, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { GlobalDialogContext, type IShownDialogProps } from './GlobalDialogContextProvider';
import { styled } from '@mui/material/styles';

interface IDialogProps {
  children: React.ReactNode
}

const CustomDialog = styled(Dialog)(() => ({
  ' .MuiPaper-root': {
    borderRadius: '12px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: 'var(--CFD-theme-Surface-4)',
    minWidth: '280px',
    maxWidth: '560px'
  },
  ' .MuiDialogContent-root': {
    padding: '0'
  },
  ' .MuiTypography-root': {
    padding: '0'
  }
}));

export const GlobalDialogContextProvider: FC<IDialogProps> = ({ children }) => {
  const [isDialogShown, setShowDialog] = useState(false);
  const [headerContent, setHeaderContent] = useState<React.ReactNode | null>(null);
  const [bodyContent, setBodyContent] = useState<React.ReactNode | null>(null);
  const [footerContent, setFooterContent] = useState<React.ReactNode | null>(null);

  const showDialog = ({
    headerContent, bodyContent, footerContent
  }: IShownDialogProps): void => {
    setShowDialog(true);
    if (headerContent !== undefined) setHeaderContent(headerContent);
    if (bodyContent !== undefined) setBodyContent(bodyContent);
    if (footerContent !== undefined) setFooterContent(footerContent);
  };

  const hideDialog = (): void => {
    setShowDialog(false);
    setHeaderContent(null);
    setBodyContent(null);
    setFooterContent(null);
  };

  const valueProvider = useMemo(() => ({
    showDialog,
    hideDialog
  }), []);

  return (
    <GlobalDialogContext.Provider value={valueProvider}>
      {isDialogShown &&
            (
            <CustomDialog
              open={isDialogShown}
              onClose={() => { setShowDialog(false); }}
            >
              {headerContent !== null && <DialogTitle>{headerContent}</DialogTitle>}
              {bodyContent !== null && <DialogContent>{bodyContent}</DialogContent>}
              {footerContent !== null && <DialogActions>{footerContent}</DialogActions>}
            </CustomDialog>
            )}
      { children }
    </GlobalDialogContext.Provider>
  );
}
