import { createSlice } from '@reduxjs/toolkit';
import { withdrawalTypesApi } from '../api/withdrawalTypesApi';

export interface WithdrawalType {
  _id: string
  name: string
  status: boolean
  createdAt: string
  updatedAt: string
}

interface WithdrawalTypesState {
  withdrawalTypes: WithdrawalType[]
  loading: boolean
  error: string | null
}

const initialState: WithdrawalTypesState = {
  withdrawalTypes: [],
  loading: false,
  error: null
};

const withdrawalTypesSlice = createSlice({
  name: 'withdrawalTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        withdrawalTypesApi.endpoints.getWithdrawalTypes.matchPending,
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        withdrawalTypesApi.endpoints.getWithdrawalTypes.matchFulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.withdrawalTypes = payload.success === true ? payload.data : [];
        }
      )
      .addMatcher(
        withdrawalTypesApi.endpoints.getWithdrawalTypes.matchRejected,
        (state, { error }) => {
          state.loading = false;
          state.error = error.message ?? 'Failed to fetch withdrawal types';
        }
      );
  }
});

export default withdrawalTypesSlice.reducer;
