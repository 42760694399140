import React, { type FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { t } from 'i18next';

import { getAccountIconBySymbol } from '../../../../../helpers/util';
import { type IAccount } from '../../../../../redux/reducers/accounts';
import BadgeSelectedIcon from '../../../../../assets/images/Badge_selected_account.svg';

import styles from './MobileAccountsTableNavigation.module.css';

interface IMobileAccountsTableNavigation {
  id?: string
  labelId?: string
  accountOption: string
  setAccountOption: (el: IAccount) => void
  accounts?: IAccount[]
}

const CustomizedFormControl = styled(FormControl)<{ isopen: string }>(({ isopen }) => ({
  height: '56px',
  borderTopLeftRadius: '28px',
  borderTopRightRadius: '28px',
  borderBottomLeftRadius: isopen === 'true' ? '0' : '28px',
  borderBottomRightRadius: isopen === 'true' ? '0' : '28px',
  backgroundColor: 'var(--CFD-theme-Surface-4) !important',
  boxSizing: 'border-box',
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-State-layers-on-surface-variant-opacity_08) !important'
  }
}))

const StyledExpandMoreIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
  color: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
  marginRight: '12px'
}));

const CustomizedMenuItem = styled(MenuItem)(({ selected }) => ({
  color: 'var(--CFD-theme-System-OnSurface)',
  minWidth: '170px',
  width: '100%',
  fontFamily: 'Arial',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  padding: '8px 12px',
  backgroundColor: 'var(--CFD-theme-Surface-4) !important',
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-Surface-5) !important'
  },
  '&.Mui-selected': {
    backgroundColor: 'var(--CFD-theme-State-layers-primary-opacity_12) !important',
    opacity: '0.88',
    color: 'var(--CFD-theme-System-OnSurface) !important'
  }
}));

const styleForSelect = {
  height: '56px',
  fontSize: '14px',
  fontßtyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  border: 'none',
  outline: 'none',
  borderTopLeftRadius: '28px',
  borderTopRightRadius: '28px',
  backgroundColor: 'var(--CFD-theme-Surface-4) !important',
  color: 'var(--CFD-theme-System-OnSurfaceVariant)',
  '.MuiPaper-root': {
    backgroundColor: 'var(--CFD-theme-Surface-4) !important'
  },
  '&:focus': {
    border: 'none',
    outline: 'none'
  },
  '& .MuiSelect-select': {
    borderTopLeftRadius: '28px',
    borderTopRightRadius: '28px',
    backgroundColor: 'var(--CFD-theme-Surface-4) !important',
    '&:focus': {
      borderTopLeftRadius: '28px',
      borderTopRightRadius: '28px',
      backgroundColor: 'var(--CFD-theme-Surface-4) !important'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}

const styleForPaperProps = {
  '&.MuiPaper-root': {
    borderBottomLeftRadius: '28px',
    borderBottomRightRadius: '28px',
    backgroundColor: 'var(--CFD-theme-Surface-4) !important',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0'
  }
}

export const MobileAccountsTableNavigation: FC<IMobileAccountsTableNavigation> = ({ setAccountOption, labelId, id, accountOption, accounts }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = ({ target: { value } }: SelectChangeEvent): void => {
    const chosenAccount = accounts?.find(({ _id }) => _id === value);
    if (chosenAccount == null) return;
    setAccountOption(chosenAccount);
  };

  return (
    <div className={ styles.wrapper }>
      <CustomizedFormControl fullWidth isopen={ isOpen.toString() }>
        <Select
          labelId={ labelId }
          id={ id }
          value={ accountOption }
          onChange={ handleChange }
          onOpen={() => { setIsOpen(true); }}
          onClose={() => { setIsOpen(false); }}
          IconComponent={StyledExpandMoreIcon}
          MenuProps={{
            MenuListProps: { disablePadding: true },
            PaperProps: {
              sx: { ...styleForPaperProps }
            }
          }}
          sx={{
            ...styleForSelect,
            borderBottomLeftRadius: isOpen ? '0' : '28px',
            borderBottomRightRadius: isOpen ? '0' : '28px',
            borderBottom: isOpen ? '1px solid var(--CFD-theme-System-OutlineVariant)' : 'none',
            '& .MuiSelect-select': {
              borderBottomLeftRadius: isOpen ? '0' : '28px',
              borderBottomRightRadius: isOpen ? '0' : '28px',
              '&:focus': {
                borderBottomLeftRadius: isOpen ? '0' : '28px',
                borderBottomRightRadius: isOpen ? '0' : '28px'
              }
            }
          }}
        >
          {accounts?.map((account) => (
            <CustomizedMenuItem
              key={account._id}
              value={account._id}
              sx={{
                backgroundColor: accountOption === account._id
                  ? 'var(--CFD-theme-State-layers-primary-opacity_12) !important'
                  : 'var(--CFD-theme-Surface-4) !important'
              }}
            >
              <div className={styles.accountContainer}>
                <img src={ getAccountIconBySymbol(account?.cfdAccountCurrency?.symbol ?? '') } className={styles.accountCurrencyIcon}/>
                <p className={styles.accountCurrencyTitle}>{ `${account.type.name} ${t('labels.account')}  ${account.cfdAccountCurrency.symbol}` }</p>
                { account?.isActive &&
                  <svg className={ styles.svg }>
                    <use xlinkHref={`${BadgeSelectedIcon}#badgeSelectedAccount`}/>
                  </svg>
                }
              </div>
            </CustomizedMenuItem>
          ))}
        </Select>
      </CustomizedFormControl>
    </div>
  )
}
