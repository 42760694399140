import React, { type FC, type MouseEvent, type ChangeEvent, type KeyboardEvent } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { FooterLiveChatComponent } from '../FooterLiveChatComponent/FooterLiveChatComponent';

import style from './LiveChatPickedFileModal.module.css'

interface InterfaceLiveChatPickedFileModal {
  removeImageHandler: () => void
  filePicked: File | null
  fileUrl: string
  sendMessageFileValue: string
  inputChangeHandler: (event: ChangeEvent<HTMLInputElement>, id: string) => void
  handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void
  messageValid: boolean
  isBtnLoading: boolean
  sendMessageHandler: () => void
}

export const LiveChatPickedFileModal: FC<InterfaceLiveChatPickedFileModal> = ({
  removeImageHandler,
  filePicked,
  fileUrl,
  sendMessageHandler,
  handleKeyDown,
  messageValid,
  inputChangeHandler,
  sendMessageFileValue,
  isBtnLoading
}) => {
  const stopPropagation = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
  };

  return (
    <div className={ style.fileContainer } onClick={ removeImageHandler }>
      <div className={ style.file } onClick={ stopPropagation }>
        <div className={ style.imgContainer }>
          <div className={ style.imageHeader }>
            <p className={ style.imageName }>
            { ((filePicked?.name?.length !== undefined) && filePicked?.name?.length > 20)
              ? filePicked?.name.slice(0, 20) + '...'
              : filePicked?.name }
            </p>
            <button className={ style.btnImg } onClick={ removeImageHandler }>
              <CancelOutlinedIcon style={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
            </button>
          </div>
          {
            filePicked?.type?.includes('image') ?? false
              ? <img src={ fileUrl } alt="Selected" className={ style.img } />
              : <InsertDriveFileIcon fontSize='large' style={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
          }
        </div>
        <div className={ style.inputModal }>
          <FooterLiveChatComponent
            sendMessageValue={ sendMessageFileValue }
            inputChangeHandler={ inputChangeHandler }
            handleKeyDown={ handleKeyDown }
            sendMessageHandler={ sendMessageHandler }
            messageValid={ messageValid }
            isBtnLoading={ isBtnLoading }
            id='caption'
          />
        </div>
      </div>
    </div>
  )
}
