import { createContext } from 'react';

export interface IShownDialogProps {
  headerContent?: React.ReactNode | null
  bodyContent?: React.ReactNode | null
  footerContent?: React.ReactNode | null
}
export const GlobalDialogContext = createContext({
  showDialog: ({ headerContent, bodyContent, footerContent }: IShownDialogProps) => {},
  hideDialog: () => {}
});
