import React, { type FC, useEffect, useState } from 'react';
import { Table, TableContainer } from '@mui/material';

import { useAppSelector } from '../../../hooks/redux';
import { StyledAccountsTableHead } from './StyledAccountsTableHead/StyledAccountsTableHead';
import { StyledAccountsTableBody } from './StyledAccountsTableBody/StyledAccountsTableBody';
import { AccountsTableSubHeader } from './AccountsTableSubHeader/AccountsTableSubHeader';
import { AccountsTableNavigation } from './AccountsTableNavigation/AccountsTableNavigation';
import { AccountsTableHeader } from './AccountsTableHeader/AccountsTableHeader';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { tableHeightCalculation } from '../helper';
import { type IAccount } from '../../../redux/reducers/accounts';

import style from './AccountsTable.module.css';

interface InterfaceAccountsTable {
  setOpenDeposit: (el: boolean) => void
  setOpenWithdrawal: (el: boolean) => void
  setOpenTransactions: (el: boolean) => void
  selectedAccount: IAccount | null
  setSelectedAccount: (el: IAccount | null) => void
}

export const AccountsTable: FC<InterfaceAccountsTable> = ({ setOpenDeposit, setOpenWithdrawal, selectedAccount, setSelectedAccount, setOpenTransactions }) => {
  const transactionsHistory = useAppSelector((state) => state.transactionsHistory)
  const [screenWidth, screenHeight] = useWindowSize();
  const [pageContentHeight, setPageContentHeight] = useState<number>(0);

  const isMobile = screenWidth <= 905;
  const isTable = screenWidth < 1240 && screenWidth > 905;

  useEffect(() => {
    if (screenHeight > 0 && screenWidth > 0) {
      const tableHeight = tableHeightCalculation(screenHeight, isMobile, isTable);
      setPageContentHeight(tableHeight);
    }
  }, [screenHeight, screenWidth]);

  return (
    <section className={ style.section }>
      <div className={ style.tableWrapper } >
        <AccountsTableNavigation selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} />
        <AccountsTableHeader
          setOpenDeposit={ setOpenDeposit }
          selectedAccount={selectedAccount}
          setOpenWithdrawal={ setOpenWithdrawal }
          setOpenTransactions={ setOpenTransactions }
          setSelectedAccount={ setSelectedAccount }
        />
          <div className={ style.expanded } >
            <AccountsTableSubHeader />
            <TableContainer
              sx={{ maxHeight: isMobile ? '100%' : `${pageContentHeight}px` }}
            >
              <Table stickyHeader sx={{ minWidth: 200 }} aria-label="customized table">
                <StyledAccountsTableHead selectedAccount={selectedAccount} />
                <StyledAccountsTableBody rows={ transactionsHistory } selectedAccount={selectedAccount} />
              </Table>
            </TableContainer>
          </div>
      </div>
    </section>
  )
}
