import React, { type FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getTransactionsHistory } from '../../redux/reducers/transactionsHistory';
import { getInvestedBalance } from '../../redux/reducers/cfd';
import { type IAccount, getAccounts } from '../../redux/reducers/accounts';
import { AccountsTable } from './AccountsTable/AccountsTable';
import { BetweenAcountsSection } from './BetweenAcountsSection/BetweenAcountsSection';
import { DepositComponent } from './DepositSection/DepositComponent'
import { WithdrawalComponent } from './WithdrawalSection/WithdrawalComponent';
import { MobileTransactionsBetweenAccounts } from './MobileTransactionsBetweenAccounts/MobileTransactionsBetweenAccounts';
import { TransactionModalContext } from '../../components'
import { DialogContextProvider } from './context/DialogContext';

import style from './Accounts.module.css';

export const AccountsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { accountId } = useParams<{ accountId?: string }>();
  const [openDeposit, setOpenDeposit] = useState<boolean>(false);
  const [openWithdrawals, setOpenWithdrawals] = useState<boolean>(false);
  const [openTransactions, setOpenTransactions] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);
  const accounts = useAppSelector((state) => state.accounts);
  const { _id: userId } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (userId !== undefined) dispatch(getAccounts(userId));
  }, [userId]);

  useEffect(() => {
    const selectAccountByIsActive = (): void => {
      const currentAccount = accounts.find((account) => account.isActive);
      setSelectedAccount(currentAccount ?? null);
    }

    const selectAccountByAcountIdParams = (): void => {
      const chosenAccount = accounts.find((el) => el._id === accountId);
      setSelectedAccount(chosenAccount ?? null);
    }

    if (selectedAccount === null) {
      selectAccountByIsActive();
      return;
    }

    if (accountId !== undefined && selectedAccount !== null) {
      if (selectedAccount._id !== accountId) selectAccountByIsActive();
      selectAccountByAcountIdParams();
    }
  }, [accounts, accountId]);

  useEffect(() => {
    if (selectedAccount !== null && userId !== undefined) {
      Promise.allSettled([
        dispatch(getTransactionsHistory({ userId, accountId: selectedAccount._id })),
        dispatch(getInvestedBalance({ userId, accountId: selectedAccount._id }))
      ]);
    }
  }, [selectedAccount, userId]);

  return (
    <DialogContextProvider>
      <main className={ style.wrapper }>
        <TransactionModalContext
          open={ openDeposit }
          setOpen={ setOpenDeposit }
          component={
            <DepositComponent setOpen={ setOpenDeposit } selectedAccount={selectedAccount} />
          }
        />
        <TransactionModalContext
          open={ openWithdrawals }
          setOpen={ setOpenWithdrawals }
          component={
            <WithdrawalComponent setOpen={ setOpenWithdrawals } selectedAccount={selectedAccount} />
          }
        />
        <TransactionModalContext
          open={ openTransactions }
          setOpen={ setOpenTransactions }
          component={
            <MobileTransactionsBetweenAccounts setOpen={ setOpenTransactions }/>
          }
        />
        {
          accounts.length > 1 && <div className={ style.betweenAccountsWrapper }>
            <BetweenAcountsSection accardionId='panel' setSelectedAccount={ setSelectedAccount } selectedAccount={ selectedAccount }/>
          </div>
        }
        <AccountsTable
          setOpenDeposit={ setOpenDeposit }
          setOpenWithdrawal={ setOpenWithdrawals }
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          setOpenTransactions={ setOpenTransactions }
        />
      </main>
    </DialogContextProvider>
  )
};
