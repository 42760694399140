import React, { type FC, useContext } from 'react';
import { GlobalDialogContext } from '../../context/GlobalDialogContext/GlobalDialogContextProvider';
import { ButtonComponent } from '../../components';
import WarningSVG from '../../assets/images/WarningsTriangle.svg';
import { t } from 'i18next';

import styles from './LiquidationModal.module.css';

export const LiquidationModalHeader: FC = (): JSX.Element => {
  return (
    <div className={styles.liquidationDialogHeader}>
      <img src={WarningSVG} className={styles.imgSize} alt='WarningSVG' />
      <span className={styles.headerTitle}>
        {t('liquidation_modal.header_title')}
      </span>
    </div>
  );
}

export const LiquidationModalContent: FC = (): JSX.Element => {
  const { hideDialog } = useContext(GlobalDialogContext);

  return (
    <div className={styles.liquidationDialogFooter}>
      <div className={styles.additionalText}>
        {t('liquidation_modal.liquidated_title')}
      </div>
      <div className={styles.additionalText}>
        {t('liquidation_modal.confirm_info_title')}
      </div>
      <div className={styles.buttonsWrapper}>
        <ButtonComponent
          customInlineStyles={{ width: '125px', height: '40px', marginTop: '19px' }}
          btnstyle='on-surface'
          variant="text"
          onClick={() => { hideDialog(); }}
        >
          {t('liquidation_modal.understood')}
        </ButtonComponent>
      </div>
    </div>
  );
}
