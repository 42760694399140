import React, { type CSSProperties, type ReactNode, type MouseEvent } from 'react';
import Button, { type ButtonProps } from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const language = localStorage.getItem('i18nextLng') ?? 'en';

interface CustomButtonProps extends ButtonProps {
  btnstyle?: 'primary' | 'on-surface' | 'buy' | 'sell'
}

interface ColorPalette {
  primary: object
  'on-surface': object
  buy: object
  sell: object
}

interface ButtonComponentType {
  children: ReactNode
  loadingIndicator: ReactNode
  btnstyle: 'primary' | 'on-surface' | 'buy' | 'sell'
  variant: 'text' | 'outlined' | 'contained'
  disabled: boolean
  loading: boolean
  startIcon: ReactNode
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  customInlineStyles: CSSProperties
  id: string
}

const colorPalette: ColorPalette = {
  primary: {
    width: '100%',
    minHeight: '40px',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: '8px',
    padding: '10px 24px',
    textTransform: 'capitalize',
    borderRadius: '2px',
    transition: 'background-color 0.3s, border-color 0.3s',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    fontFamily: language === 'ar' ? "'Cairo-Regular', sans-serif" : "'Arial Regular', Arial, sans-serif",

    backgroundColor: 'var(--CFD-theme-System-Tertiary)',
    color: 'var(--CFD-theme-System-OnTertiary)',

    '&:hover': {
      backgroundColor: 'var(--CFD-theme-System-Tertiary)',
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'
    },
    '&:active': {
      backgroundColor: 'var(--CFD-theme-System-Tertiary)'
    },
    '&:disabled': {
      color: 'var(--CFD-theme-System-Outline)',
      backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_12)'
    },

    '&.MuiButton-outlined': {
      backgroundColor: 'transparent',
      color: 'var(--CFD-theme-System-Tertiary)',
      border: '1px solid var(--CFD-theme-System-Outline)'
    },
    '&.MuiButton-outlined:hover': {
      backgroundColor: 'var(--CFD-theme-State-layers-on-tertiary-container-opacity_08)'
    },
    '&.MuiButton-outlined:active': {
      backgroundColor: 'var(--CFD-theme-State-layers-on-tertiary-container-opacity_12)'
    },
    '&.MuiButton-outlined:disabled': {
      color: 'var(--CFD-theme-System-Outline)',
      backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_12)'
    },
    '&.MuiButton-text': {
      backgroundColor: 'transparent',
      color: 'var(--CFD-theme-System-Tertiary)',
      padding: '10px 12px'
    },
    '&.MuiButton-text:hover': {
      backgroundColor: 'var(--CFD-theme-State-layers-on-tertiary-container-opacity_08)'
    },
    '&.MuiButton-text:active': {
      backgroundColor: 'var(--CFD-theme-State-layers-on-tertiary-container-opacity_12)'
    },
    '&.MuiButton-text:disabled': {
      color: 'var(--CFD-theme-System-Outline)'
    }
  },
  'on-surface': {
    width: '100%',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: '8px',
    padding: '10px 24px',
    textTransform: 'capitalize',
    borderRadius: '2px',
    transition: 'background-color 0.3s, border-color 0.3s',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    fontFamily: language === 'ar' ? "'Cairo-Regular', sans-serif" : "'Arial Regular', Arial, sans-serif",

    backgroundColor: 'var(--CFD-theme-System-Primary)',
    color: 'var(--CFD-theme-System-OnPrimary)',

    '&:hover': {
      backgroundColor: 'var(--CFD-theme-System-Primary)',
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'
    },
    '&:active': {
      backgroundColor: 'var(--CFD-theme-System-Primary)'
    },
    '&:disabled': {
      color: 'var(--CFD-theme-System-Outline)',
      backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_12)'
    },

    '&.MuiButton-outlined': {
      backgroundColor: 'transparent',
      color: 'var(--CFD-theme-System-Primary)',
      border: '1px solid var(--CFD-theme-System-Outline)'
    },
    '&.MuiButton-outlined:hover': {
      backgroundColor: 'var(--CFD-theme-State-layers-primary-opacity_08)'
    },
    '&.MuiButton-outlined:active': {
      backgroundColor: 'var(--CFD-theme-State-layers-primary-opacity_12)'
    },
    '&.MuiButton-outlined:disabled': {
      color: 'var(--CFD-theme-System-Outline)',
      backgroundColor: 'transparent'
    },
    '&.MuiButton-text': {
      backgroundColor: 'transparent',
      color: 'var(--CFD-theme-System-Primary)',
      padding: '10px 12px'
    },
    '&.MuiButton-text:hover': {
      backgroundColor: 'var(--CFD-theme-State-layers-primary-opacity_08)'
    },
    '&.MuiButton-text:active': {
      backgroundColor: 'var(--CFD-theme-State-layers-primary-opacity_12)'
    },
    '&.MuiButton-text:disabled': {
      color: 'var(--CFD-theme-System-Outline)'
    }
  },
  buy: {
    width: '100%',
    minHeight: '40px',
    display: 'flex',
    padding: '4px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    color: 'var(--CFD-theme-Custom-OnGreen)',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textTransform: 'capitalize',
    borderRadius: '2px',
    background: 'var(--CFD-theme-Custom-Green)',
    fontFamily: language === 'ar' ? "'Cairo-Regular', sans-serif" : "'Arial Regular', Arial, sans-serif",
    flexDirection: 'column',

    '&:hover': {
      background: 'var(--CFD-theme-Custom-Green)',
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'
    },
    '&:active': {
      backgroundColor: 'var(--CFD-theme-State-layers-on-green-opacity_12)'
    },
    '&:disabled': {
      color: 'var(--CFD-theme-System-Outline)',
      backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_12)'
    },
    ' .btn-text': {
      textAlign: 'center',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.5px'
    },
    ' .btn-price': {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '20px',
      letterSpacing: '0.1px'
    }
  },
  sell: {
    width: '100%',
    minHeight: '40px',
    display: 'flex',
    padding: '4px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    color: 'var(--CFD-theme-Custom-OnGreen)',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textTransform: 'capitalize',
    borderRadius: '2px',
    background: 'var(--CFD-theme-Custom-Red)',
    fontFamily: language === 'ar' ? "'Cairo-Regular', sans-serif" : "'Arial Regular', Arial, sans-serif",
    flexDirection: 'column',

    '&:hover': {
      background: 'var(--CFD-theme-Custom-Red)',
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'
    },
    '&:active': {
      backgroundColor: 'var(--CFD-theme-State-layers-on-red-opacity_12)'
    },
    '&:disabled': {
      color: 'var(--CFD-theme-System-Outline)',
      backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_12)'
    },
    ' .btn-text': {
      textAlign: 'center',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.5px'
    },
    ' .btn-price': {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '20px',
      letterSpacing: '0.1px'
    }
  }
};

const CustomButton = styled(Button)<CustomButtonProps>(({ btnstyle = 'primary' }) => {
  const palette = colorPalette[btnstyle];
  return {
    ...palette
  };
});

export const ButtonComponent = React.forwardRef<HTMLButtonElement, Partial<ButtonComponentType>>((props, ref) => {
  const {
    children,
    btnstyle = 'primary',
    variant = 'contained',
    disabled = false,
    loading = false,
    loadingIndicator = <CircularProgress color="inherit" size={16} />,
    customInlineStyles,
    ...rest
  } = props;

  return (
    <CustomButton
      ref={ref}
      variant={variant}
      disabled={disabled || loading}
      btnstyle={btnstyle}
      style={customInlineStyles}
      {...rest}
    >
      {loading ? loadingIndicator : children}
    </CustomButton>
  );
});

ButtonComponent.displayName = 'ButtonComponent';
