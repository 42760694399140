import React, { useEffect, type FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux';
import { type RouteLayoutInterface } from '../interfaces';

export const PublicLayout: FC<RouteLayoutInterface> = ({ isUserAuth, children }): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { startAsset } = useAppSelector(state => state.cfd);

  useEffect(() => {
    if (location.pathname === '/' && startAsset.length > 0) navigate(isUserAuth ? `/trade?asset=${startAsset}` : '/login');
  }, [isUserAuth, startAsset]);

  return (
    <>
      {children}
    </>
  );
}
