export enum AccountsElementHeight {
  PaddingTop = 16,
  PaddingBottom = 14,
  GapBetweenTableTransfer = 16,
  TableNavigation = 48,
  TableSubHeader = 202,
  TableHeaderHistoryRow = 44,
  TablePadding = 32,
  TransferAccordion = 58,
  Footer = 64,
  Gap = 20,
  Header = 52,
  MobileSubHeader = 364
}

export const modalsAssetsSpacing = {
  Crypto: 155,
  Card: 130,
  Bank: 20,
  Other: 50
}

export const isCardValid = (value: string): boolean => {
  const valueWithoutSpaces = value.replace(/\s/g, '');
  const validCardNumber = valueWithoutSpaces.length >= 16 && valueWithoutSpaces.length <= 19;
  return validCardNumber
}

export const tableHeightCalculation = (screenHeight: number, isMobile: boolean, isTable: boolean): number => {
  const minimalTableHeight = 306;
  let tableHeight = 0;

  if (!isMobile && !isTable) {
    tableHeight = (AccountsElementHeight.TableSubHeader + AccountsElementHeight.TableNavigation + AccountsElementHeight.GapBetweenTableTransfer +
      AccountsElementHeight.TableHeaderHistoryRow + AccountsElementHeight.TransferAccordion);
  }

  if (isTable && !isMobile) {
    tableHeight = (AccountsElementHeight.TableSubHeader + AccountsElementHeight.TableNavigation + AccountsElementHeight.GapBetweenTableTransfer +
    AccountsElementHeight.TableHeaderHistoryRow);
  }

  const pageContent = tableHeight + AccountsElementHeight.PaddingTop + AccountsElementHeight.PaddingBottom;
  const allPageContentHeight = screenHeight - pageContent - AccountsElementHeight.Footer - AccountsElementHeight.Header;

  const height = allPageContentHeight > minimalTableHeight ? allPageContentHeight : minimalTableHeight;

  return height;
}
